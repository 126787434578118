import React, { Component } from "react";
import "../../scss/Checkout.scss";
// import Constants from './common/Constants';
import { Link, Navigate } from "react-router-dom";
import Lottie from "lottie-react-web";
import loader from "../../images/loader.json";
import Modal from "react-awesome-modal";
import axios from "axios";
import Constants from "../../components/common/Constants";
import init from "../../components/helper/WindowToken";
import webAkLogo from "../../images/akLogo.png";
import checkoutImg from "../../images/checkoutImg.png";
import toofanimg from "../../images/toofanimg.png";
import ak_classes_book from "../../images/ak_classes_book.png";
import selectedCourse from "../../images/selectedCourse.png";
import Tooltip from "@material-ui/core/Tooltip";
import check from "../../images/check.png";
import { v4 as uuidv4 } from "uuid";
import sha256 from "crypto-js/sha256";
import closeEyeImg from "../../images/closeEyeImg.png";
import eye from "../../images/eyeImg.png";
import userImage from "../../images/account.png";
import secure from "../../images/secure.png";
// import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/Animation - 1733570361351.json'; // Ensure the file path is correct
import animationSuccess from '../../assets/lottie/Animation - 1733748315145.json'; // Ensure the file path is correct

window.telCode = "+91";
window.mobile = "";
window.fName = "";
window.name = "";
window.email = "";
window.social_token = "";
window.otpView = false;
window.otpVerified = false;
window.payLoad = {
  fname: "",
  lname: "",
  city: "",
  state: "",
  visible: false,
  warn1: false,
  warn2: false,
  mobile: "",
  address: 0,
  discount: 0,
  taxAmount: 0,
  coupon: 0,
  pincode: "",
  email: "",
  subTotal: 0,
  couponId: 0,
  payMode: "",
  DeliveryCharge: "",
  cartData: [],
  pincodeList: [],
  shippingAddress: [],
  cartId: 0,
  grand_total: 0,
  extendproductPrice: "",
  last_name: "",
  // coupon: 0,
  attempt: 0,
  product_detail_for_order: {},
  openEye: false,
  sku: "",
  showErrMsg: "",
  courseDetails: "",
};

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // cartdetails: this.props.cartData,
      redeemFromWallet: false,
      sub_total: 0,
      redirect: false,
      showButtons: true,
      media: [],
      os: [],
      view: [],
      sampleArray: [],
      login: false,
      courses: [
        { label: "9th Standard", value: "9th Standard" },
        { label: "10th Standard", value: "10th Standard" },
        { label: "CA Foundation", value: "CA Foundation" },
        { label: "CA Inter", value: "CA Inter" },
        { label: "Ca Final", value: "Ca Final" },
      ],
      userInfo: {},
      course: "",
      regForm: false,
      userdetails: [],
      userDetails: this.props.userDetails,
      coupon: "",
      discount: 0,
      contact_no: "",
      cartId: 0,
      address_2: "",
      last_name: "",
      first_name: "",
      FIRST_NAME: "",
      mobile: "",
      //   email: this.props.userDetails.email,
      address_1: "",
      city: "",
      pincode: "",
      state: "",
      product_details: [],
      add_1: "",
      add_2: "",
      quantity: [],
      orderId: [],
      shippingAddress: [],
      shipping: 0,
      name: "",
      total: 0,
      //   FIRST_NAME: this.props.userDetails.FIRST_NAME,
      EMAIL: "",
      email: "",
      PASSWORD: "",
      password_confirmation: "",
      redirectLogin: false,
      register: [],
      redirectToaddress: false,
      mediaArray: [],
      viewArray: [],
      showAddressForm: false,
      product: {},
      product_detail_for_order: {},
      base_total: 0,
      grand_total: 0,
      coupon_amount: 0,
      showLogin: true,
      btnLoading: false,
      showNotif: false,
      notiMessage: "",
      attempt: "",
      showError: false,
      extendproductPrice: "",
      extendProductName: "",
      showshippingForm: false,
      showLoginForm: false,
      editAdd: false,
      success: false,
      stateM: "",
      otp: "",
      mobileNo: "",
      hash: "",
      wallet_balance: "",

      cId: 0,
      oData: {},
      // htmlcode:"",
      // visibleMdoal:false
      minutes: 1,
      seconds: 0,
      courseId: "",
      sku: "",
      payment_gateway: "",
      userDetailId: "",
      userDetailNumber: "",
      placeOrderError: false,
      placeOrderMessage: "",
      placeOrderButton: false,
      updateName: "",
      updateEmail: "",
      courseDetails: "",
      userDetails: "",
      editCompleteMsg: false,
      defaultPage: false,
      regForm: false,
      billingshipping: true,
      accountDetails: false,
      loginScreen: false,
      storedOrderId: "",
      baseAmount: "",
      isLoading: false,
      isOrderLoading: false,
    };
  }

  componentDidMount() {
    // console.log("INIT",init())
    // return;
    if (init() === "success") {
      this.fetchWalletBalance();
      this.setState({
        placeOrderButton: true,
        billingshipping: false,
        accountDetails: true,
      });
    } else {
      this.setState({
        placeOrderButton: false,
      });
    }
    var params = new URLSearchParams(window.location.search);
    var urls = window.location.href;
    //Get Params
    var bookIdParams = params.get("bookId");
    console.log(bookIdParams);
    var bookId = atob(bookIdParams);
    console.log(bookId);
    if (bookIdParams) {
      this.setState(
        {
          courseId: bookId,
        },
        () => {
          console.log(this.state.courseId);
          axios
            .get(Constants.getUrls.courseDetail + this.state.courseId)
            .then((resp) => {
              console.log(resp, "DOCUMENTS");
              this.setState(
                {
                  product: resp.data.product,
                  base_total: resp.data.product.PRICE,
                  grand_total: resp.data.product.PRICE,
                  sku: resp.data.product.SKU,
                  product_details: {
                    TITLE: resp.data.product?.TITLE,
                    product_id: resp.data.product.ID,
                    quantity: 1,
                    type: "BOOKS",
                    variant_id:
                      resp.data.product.variant.length > 0
                        ? resp.data.product.variant[0].id
                        : "",
                    os_type_id: resp.data.product.variant.length
                      ? resp.data.product.variant[0].os_type_id
                      : "",
                    media_type_id: resp.data.product.variant.length
                      ? resp.data.product.variant[0].media_type_id
                      : "",
                    attempt: resp.data.product.attempt,
                    view_and_validity_id: resp.data.product.variant.length
                      ? resp.data.product.variant[0].view_and_validity_id
                      : "",
                  },
                },
                () => {
                  window.payLoad.product_detail_for_order =
                    this.state.product_details;
                  window.payLoad.grand_total = this.state.grand_total;
                  window.payLoad.sku = this.state.sku;
                  window.payLoad.courseDetails = resp.data.product;
                  window.payLoad.attempt = this.state.attempt;
                }
              );
            });
          console.log(window.payLoad.courseDetails, "Course Details");
        }
      );
    }

    console.log(this.props);
    this.getUserInfo();
    console.log(localStorage.token);
    const props = this.props;
    console.log(this.props);
    console.log(props);
    if (!props) {
      window.location.href = "/";
    }
    if (
      props?.userDetails?.ADDRESS_1 == null &&
      props?.userDetails?.ADDRESS_2 == null &&
      localStorage.getItem("added") === "false"
    ) {
      //   this.setState({ showButtons: false, showAddressForm: true });
    }

    this.setState(
      {
        courseDetails: props.courseDetails,
        userDetails: props.userDetails,
        userDetailNumber: props.userDetails?.MOBILE,
        userDetailId: props.userDetails?.ID,
        payment_gateway: props.product_details?.payment_gateway,
        product: props.product,
        attempt: props?.courseDetails?.attempt,
        product_detail_for_order: props.product_details,
        coupon: props.coupon,
        base_total: props.base_total,
        coupon_amount: props.coupon_amount,
        grand_total: props.coupon
          ? parseInt(props.base_total) - parseInt(props.coupon_amount)
          : parseInt(props.base_total),
        extendProductName: props?.product_details?.TITLE
          ? props?.product_details?.TITLE
          : null,
        sku: props?.sku ? props?.sku : null,

        // ? props.product.product.TITLE
        // : null
        // extendproductPrice: props.product.product.extend_course_price
        extendproductPrice: props.product_price ? props.product_price : null,

        // extendDetails:props.
      },
      () => {
        console.log(this.state.courseDetails);
        console.log(props.product_details);
        localStorage.setItem(
          "payment_gateway",
          this.state.courseDetails.payment_gateway
        );
        localStorage.setItem(
          "phonepe_amount",
          this.state.grand_total
            ? this.state.grand_total === null
              ? 0
              : this.state.grand_total
            : this.state.extendproductPrice === null
            ? 0
            : this.state.extendproductPrice
        );

        sessionStorage.setItem(
          "payment_gateway",
          this.state.courseDetails.payment_gateway
        );
        sessionStorage.setItem(
          "phonepe_amount",
          this.state.grand_total
            ? this.state.grand_total === null
              ? 0
              : this.state.grand_total
            : this.state.extendproductPrice === null
            ? 0
            : this.state.extendproductPrice
        );

        console.log(this.state.courseDetails.payment_gateway);
        window.payLoad.coupon = this.state.coupon;
        window.payLoad.product_detail_for_order =
          this.state.product_detail_for_order;
        window.payLoad.grand_total = this.state.grand_total;
        window.payLoad.sku = this.state.sku;
        window.payLoad.attempt = this.state.attempt;
      }
    );
    // axios.get(Constants.getUrls.getBooks + "?category_id=" + this.state.courseDetails.CATEGORY_ID + "&level_id=" + this.state.courseDetails.LEVEL_ID + "&stream_id=" + this.state.courseDetails.STREAM_ID + "&subject_id=" + this.state.courseDetails.subject_master_id)
    // .then(resbook => {
    //   console.log(resbook);
    // })
    if (localStorage.token) {
      this.setState({
        login: true,
      });
    }
  }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        console.log(res.data);
        localStorage.setItem("user_id", res.data.ID);
        localStorage.setItem("mobile_number", res.data.MOBILE);

        sessionStorage.setItem("user_id", res.data.ID);
        sessionStorage.setItem("mobile_number", res.data.MOBILE);

        this.setState(
          {
            userInfo: res.data,
            userDetails: res.data,
          },
          () => {
            console.log(this.state.userInfo);
            this.setState(
              {
                name: this.props.userDetails?.FIRST_NAME
                  ? this.props.userDetails?.FIRST_NAME
                  : this.state.userInfo.FIRST_NAME,
                email: this.props.userDetails?.email
                  ? this.props.userDetails?.email
                  : this.state.userInfo.email,
                mobile: parseInt(
                  this.props.userDetails?.MOBILE
                    ? this.props.userDetails?.MOBILE
                    : this.state.userInfo.MOBILE
                ),
                city: this.props.userDetails?.CITY
                  ? this.props.userDetails?.CITY
                  : this.state.userInfo.CITY,
                state: this.props.userDetails?.STATE
                  ? this.props.userDetails?.STATE
                  : this.state.userInfo.STATE,
                address_1: this.state.userInfo?.ADDRESS_1,
                address_2: this.props.userDetails?.ADDRESS_2
                  ? this.props.userDetails?.ADDRESS_2
                  : this.state.userInfo.ADDRESS_2,
                pincode: parseInt(
                  this.props.userDetails?.PINCODE
                    ? this.props.userDetails?.PINCODE
                    : this.state.userInfo.PINCODE
                ),
              },
              () => {
                console.log(this.state.address_1);
              }
            );
          }
        );
      });
    }
  };

  fetchWalletBalance = () => {
    axios.get(Constants.getUrls.getWalletBalance).then((response) => {
      console.log("User Wallet Data", response?.data?.balance);
      console.log(
        "User Course Details",
        this.props?.courseDetails?.THUMB_IMAGE_PATH
      );
      this.setState({
        wallet_balance: response?.data?.balance || "",
      });
    });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      redeemFromWallet: !prevState.redeemFromWallet,
    }));
  };

  calculateTotal = () => {
    // const {
    //   courseDetails,
    //   grand_total,
    //   extendproductPrice,
    //   coupon_amount,
    //   redeemFromWallet,
    //   wallet_balance,
    // } = this.state;

    // Base price based on course type
    let basePrice =
      this.props.courseDetails.TYPE === "BOOKS"
        ? this.props.courseDetails?.PRICE - (this.state.coupon_amount || 0)
        :  this.props.grand_total !== null
        ?  this.props.grand_total
        : this.state.extendproductPrice || 0;

        console.log("Base Price",this.props.courseDetails?.PRICE || this.props.grand_total );
        

    // Deduct wallet balance if redeemFromWallet is true
    if (this.state.redeemFromWallet && this.state.wallet_balance) {
      basePrice = Math.max(basePrice - this.state.wallet_balance, 0); // Ensure total does not go below 0
    }
    // this.setState({
    //   baseAmount:basePrice,
    // })

    return basePrice;
  };

  // componentDidMount() {
  //   if (localStorage.token) {
  //     this.fetchWalletBalance();
  //   } else {
  //     console.log("Please Login first");
  //   }
  // }
  // closeModal() {
  //   this.setState({
  //     paymentError: false,
  //   });
  // }

  componentDidUpdate(nextProps) {
    if (this.props.userDetails !== nextProps.userDetails) {
      this.setState(
        {
          userDetails: nextProps.userDetails,
          FIRST_NAME: this.props.userDetails.FIRST_NAME,
          LAST_NAME: this.props.userDetails.LAST_NAME,
          EMAIL: this.props.userDetails.email,
        },
        () => {
          this.setState({
            name: this.props.userDetails.FIRST_NAME
              ? this.props.userDetails.FIRST_NAME
              : this.state.userInfo.FIRST_NAME,
            email: this.props.userDetails.email
              ? this.props.userDetails.email
              : this.state.userInfo.email,
            mobile: parseInt(
              this.props.userDetails.MOBILE
                ? this.props.userDetails.MOBILE
                : this.state.userInfo.MOBILE
            ),
            city: this.props.userDetails.CITY
              ? this.props.userDetails.CITY
              : this.state.userInfo.MOBILE,
            state: this.props.userDetails.STATE
              ? this.props.userDetails.STATE
              : this.state.userInfo.MOBILE,
            address_1: this.props.userDetails.ADDRESS_1
              ? this.props.userDetails.ADDRESS_1
              : this.state.userInfo.ADDRESS_1,
            address_2: this.props.userDetails.ADDRESS_2
              ? this.props.userDetails.ADDRESS_2
              : this.state.userInfo.ADDRESS_2,
            pincode: parseInt(
              this.props.userDetails.PINCODE
                ? this.props.userDetails.PINCODE
                : this.state.userInfo.PINCODE
            ),
          });
        }
      );
    }
  }
  validateName = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        name: text,
        showCorrectName: false,
        showNameError: true,
      });
      return false;
    } else {
      this.setState({
        name: text,
        showCorrectName: true,
        showNameError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
      console.log("Email is Correct");
    }
  };
  validateEmail1 = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        EMAIL1: text,
        showCorrectEmail1: false,
        showEmailError1: true,
      });
      return false;
    } else {
      this.setState({
        EMAIL1: text,
        showCorrectEmail1: true,
        showEmailError1: false,
      });
      console.log("Email is Correct");
    }
  };

  validateMobile(text) {
    const reg = /^[0]?[6789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNumber: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNumber: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }
  validateCity = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        city: text,
        showCorrectCity: false,
        showCityError: true,
      });
      return false;
    } else {
      this.setState({
        city: text,
        showCorrectCity: true,
        showCityError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateState = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        stateM: text,
        showCorrectState: false,
        showStateError: true,
      });
      return false;
    } else {
      this.setState({
        stateM: text,
        showCorrectState: true,
        showStateError: false,
      });
      console.log("Name is Correct");
    }
  };
  validatePincode(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState(
        {
          showCorrectPincode: false,
          pincode: text,
          showPincodeError: true,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return false;
    } else {
      this.setState(
        {
          showCorrectPincode: true,
          pincode: text,
          showPincodeError: false,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return true;
    }
  }
  registerUser = (e) => {
    // if (
    //   this.state.showCorrectName &&
    //   this.state.showCorrectCity &&
    //   this.state.showCorrectEmail &&
    //   this.state.showCorrectState &&
    //   this.state.validateMobile &&
    //   this.state.password_confirmation === this.state.PASSWORD
    // ) {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      showNotif: true,
      notiMessage: "Creating Account...",
      showError: false,
    });
    const payLoad = {
      FIRST_NAME: this.state.FIRST_NAME,
      EMAIL: this.state.EMAIL,
      course: this.state.course,
      PASSWORD: this.state.PASSWORD,
      mobile: this.state.mobile,
      password_confirmation: this.state.password_confirmation,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      city: this.state.city,
      pincode: this.state.pincode,
      state: this.state.state,
      mobile: this.state.mobile,
      shippingAddress: this.state.shippingAddress,
    };
    console.log(payLoad);
    axios.post(Constants.postUrls.register, payLoad).then((res) => {
      console.log("reg", res);
      if (res.data.status === "success") {
        this.setState({
          register: res.data.status,
        });
        e.preventDefault();
        this.setState({
          btnLoading: true,
          showNotif: true,
          notiMessage: "Signing in...",
          showError: false,
        });
        const payload = {
          email: this.state.EMAIL,
          password: this.state.PASSWORD,
          status: "main",
        };
        console.log(payload);
        axios.post(Constants.postUrls.login, payload).then((res) => {
          console.log("login", res);
          if (res.data.status === "success") {
            this.setState({
              logindata: res.data.data,
              placeOrderButton: true,
            });
            localStorage.setItem("token", res.data.token);
            sessionStorage.setItem("token", res.data.token);

            if (init() === "success") {
              this.setState({
                login: true,
                btnLoading: false,
              });
              // this.props.onUpdateUser();

              e.preventDefault();
              this.setState({
                btnLoading: true,
                showNotif: true,
                notiMessage: "Adding address...",
              });
              // console.log(this.props.userDetails);
              const payload = {
                name: this.state.FIRST_NAME
                  ? this.state.FIRST_NAME
                  : this.state.userDetails.FIRST_NAME,
                first_name: this.state.FIRST_NAME
                  ? this.state.FIRST_NAME
                  : this.state.userDetails.FIRST_NAME,
                last_name: "",
                email: this.state.EMAIL,
                address_1: this.state.address_1,
                address_2: this.state.address_2,
                city: this.state.city,
                pincode: this.state.pincode,
                state: this.state.state,
                mobile: this.state.mobile,
                shippingAddress: this.state.shippingAddress,
              };
              console.log(payload);
              axios
                .post(Constants.postUrls.updateAddress, payload)
                .then((res) => {
                  console.log("newadd", res.data.data);
                  this.setState({
                    name: res.data.data.FIRST_NAME,
                    email: res.data.data.email,
                    mobile: parseInt(res.data.data.MOBILE),
                    city: res.data.data.CITY,
                    state: res.data.data.STATE,
                    address_1: res.data.data?.ADDRESS_1,
                    address_2: res.data.data.ADDRESS_2,
                    pincode: parseInt(res.data.data.PINCODE),
                    btnLoading: false,
                    userDetails: res.data.data,
                  });
                  if (res.data.status === "success") {
                    // window.location.reload();
                    this.setState(
                      {
                        shippingAddress: res.data.data,
                        newAdd: res.data.data,
                        billingshipping: false,
                        accountDetails: true,
                      },
                      () => {
                        window.payLoad.shippingAddress =
                          this.state.shippingAddress;
                        if (
                          this.state.newAdd?.email &&
                          this.state.newAdd?.ADDRESS_1
                        ) {
                          console.log(this.state.grand_total);
                          this.placeOrder();
                          // if (this.state.grand_total != 0) {
                          //   axios
                          //     .post(Constants.postUrls.createOrderId, {
                          //       // amount: (
                          //       //   this.state.grand_total * 100
                          //       // ).toString(),
                          //       amount:
                          //         this.state.courseDetails.TYPE === "BOOKS"
                          //           ? (
                          //               (this.state.courseDetails.PRICE -
                          //                 (this.state.coupon_amount || 0)) *
                          //               100
                          //             ).toString()
                          //           : (
                          //               (this.state.grand_total -
                          //                 (this.state.coupon_amount || 0)) *
                          //               100
                          //             ).toString(),
                          //     })
                          //     .then((resp) => {
                          //       this.setState(
                          //         {
                          //           orderId: resp.data,
                          //         },
                          //         () => {
                          //           console.log(this.state.orderId);
                          //           let options = {
                          //             // key: "rzp_live_rCkJJMueXcoaZV",
                          //             key: "rzp_live_rCkJJMueXcoaZV",
                          //             amount: this.state.grand_total,
                          //             name: "Ak Classes",
                          //             order_id: this.state.orderId,
                          //             description: "Ak Classes",
                          //             image: webAkLogo,
                          //             handler: function (response) {
                          //               console.log(response);
                          //               let token =
                          //                 localStorage.getItem("token");
                          //               console.log(token);
                          //               axios
                          //                 .get(Constants.getUrls.user, {
                          //                   headers: {
                          //                     Authorization: "Bearer " + token,
                          //                   },
                          //                 })
                          //                 .then((resp) => {
                          //                   let winLoad = {
                          //                     flag: "Website",
                          //                     razorpay_order_id:
                          //                       response.razorpay_order_id,
                          //                     razorpay_payment_id:
                          //                       response.razorpay_payment_id,
                          //                     razorpay_signature:
                          //                       response.razorpay_signature,
                          //                     contact_no: parseInt(
                          //                       resp.data.MOBILE
                          //                     ),
                          //                     sku: window.payLoad.sku,
                          //                     address:
                          //                       window.payLoad
                          //                         .shippingAddress ===
                          //                       "selected"
                          //                         ? window.payLoad
                          //                             .shippingAddress
                          //                         : resp.data.ADDRESS_1,
                          //                     // ,
                          //                     // address: "",
                          //                     cart: window.payLoad.cartId,
                          //                     address_2:
                          //                       window.payLoad.shippingAddress
                          //                         .ADDRESS_2,
                          //                     grand_total: window.payLoad
                          //                       .grand_total
                          //                       ? window.payLoad.grand_total ===
                          //                         null
                          //                         ? 0
                          //                         : window.payLoad.grand_total
                          //                       : window.payLoad
                          //                           .extendproductPrice === null
                          //                       ? 0
                          //                       : window.payLoad
                          //                           .extendproductPrice,
                          //                     // grand_total: "",
                          //                     last_name:
                          //                       window.payLoad.shippingAddress
                          //                         .last_name,
                          //                     first_name:
                          //                       window.payLoad.shippingAddress
                          //                         .FIRST_NAME,
                          //                     mobile: parseInt(
                          //                       resp.data.MOBILE
                          //                     ),
                          //                     email:
                          //                       window.payLoad.shippingAddress
                          //                         .email,
                          //                     state:
                          //                       window.payLoad.shippingAddress
                          //                         .STATE,

                          //                     city: window.payLoad
                          //                       .shippingAddress.CITY,
                          //                     pincode: parseInt(
                          //                       resp.data.PINCODE
                          //                     ),
                          //                     country: "INDIA",
                          //                     coupon: window.payLoad.coupon,
                          //                     attempt: window.payLoad.attempt,
                          //                     shipping: 0,
                          //                     base_total: window.payLoad
                          //                       .grand_total
                          //                       ? window.payLoad.grand_total ===
                          //                         null
                          //                         ? 0
                          //                         : window.payLoad.grand_total
                          //                       : window.payLoad
                          //                           .extendproductPrice === null
                          //                       ? 0
                          //                       : window.payLoad
                          //                           .extendproductPrice,
                          //                     product_details: [
                          //                       window.payLoad
                          //                         .product_detail_for_order,
                          //                     ],
                          //                   };
                          //                   console.log("winload", winLoad);
                          //                   axios
                          //                     .post(
                          //                       Constants.postUrls.storeOrder,
                          //                       winLoad
                          //                     )
                          //                     .then((res) => {
                          //                       console.log("storeOrder", res);
                          //                       if (
                          //                         res.data.status === "success"
                          //                       ) {
                          //                         window.location.href =
                          //                           "/payment-success";
                          //                         axios
                          //                           .post(
                          //                             Constants.postUrls
                          //                               .clearCart
                          //                           )
                          //                           .then((resp) => {
                          //                             console.log(resp);
                          //                           });
                          //                       }
                          //                     });
                          //                 });
                          //             },
                          //             prefill: {
                          //               email: window.payLoad.email,
                          //               contact: "91" + window.payLoad.mobile,
                          //             },
                          //             theme: {
                          //               color: "#0f66b9",
                          //             },
                          //           };
                          //           let rzp = new window.Razorpay(options);
                          //           rzp.open();
                          //         }
                          //       );
                          //     });
                          // } else {
                          //   window.location.href = "/order-placed";
                          // }
                        } else {
                          alert("Address or Email missing");
                        }
                      }
                    );
                  }
                });
            }
          } else {
            this.setState({
              showError: true,
              showErrMsg: res.data.message
                ? res.data.message
                : res.data.credential,
              btnLoading: false,
              // showNotif: from !== "register" ? false : true
            });
          }
        });
      } else {
        this.setState({
          showError: true,
          showErrMsg: res.data.message,
          btnLoading: false,
          showNotif: false,
        });
      }
    });
    // } else {
    //   alert("Enter Valid Credentials");
    // }
    this.setState({
      btnLoading: false,
    });
  };

  loginUserOTP = (e) => {
    e.preventDefault();
    this.setState({ loaderLogin: true });
    const payload = {
      mobile: this.state.mobileNo,
      name: "",
      hash_key: this.state.hash,
    };
    // console.log(payload);
    axios
      .post(Constants.postUrls.sendOTP, payload)
      .then((res) => {
        console.log(res);

        if (res.data.status === "success") {
          this.setState({
            // showLogin: false,
            OTPModal: true,
            otpSent: "Otp Sent",
            from: "Login",
            loaderLogin: false,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  loginUser = (e, from) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      showNotif: true,
      notiMessage: "Signing in...",
      showError: false,
    });
    const payload = {
      email: this.state.EMAIL1,
      password: this.state.PASSWORD,
      status: "main",
    };
    // console.log(payload);
    axios.post(Constants.postUrls.login, payload).then((res) => {
      // console.log(res.data);
      if (res.data.status === "success") {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("login", res.data);

        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("login", res.data);

        // init();
        if (init() === "success") {
          // let cartItem = JSON.parse(localStorage.getItem("cart"));
          this.setState({
            login: true,
            btnLoading: false,
          });
          // this.props.onUpdateUser();
          this.getUserInfo();
          window.location.reload();
          if (from === "register") {
            // axios.get(Constants.getUrls.user).then(res => {
            //   console.log(res);
            //   this.setState({
            //     userdetails: res.data
            //     // login: true
            //   });
            // });
            // console.log("Add Address");
            this.addUserAddress(e);
            // this.placeOrder(e);
          } else {
            window.location.reload();
          }
        }
      } else {
        this.setState({
          showError: true,
          btnLoading: false,
          showNotif: from !== "register" ? false : true,
        });
      }
    });
  };
  addUserAddress = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      showNotif: true,
      notiMessage: "Adding address...",
    });

    const payload = {
      name: this.state.userInfo.FIRST_NAME,
      first_name: this.state.userInfo.FIRST_NAME,
      last_name: "",
      email: this.state.userInfo.email,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      city: this.state.city,
      pincode: this.state.pincode,
      state: this.state.state,
      mobile: this.state.mobile,
      shippingAddress: this.state.shippingAddress,
    };
    // console.log(payload);
    axios.post(Constants.postUrls.updateAddress, payload).then((res) => {
      // console.log(res);
      localStorage.setItem("added", "true");
      window.location.reload();
      this.setState(
        {
          shippingAddress: res.data,
          showButtons: true,
          btnLoading: false,
        },
        () => {
          window.payLoad.shippingAddress = this.state.shippingAddress;
        }
      );
    });
  };
  // reload = () => {};
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  postCoupon = () => {
    const payload = {
      coupon: parseInt(this.state.coupon),
    };
    // console.log(payload);
    axios.post(Constants.postUrls.coupon, payload).then((res) => {
      // console.log(res);
      this.setState(
        {
          discount: res.data.Discount,
          grand_total: parseInt(this.state.sub_total - this.state.discount),
        },
        () => {
          window.payLoad.coupon = parseInt(this.state.coupon);
          console.log(window.payLoad.coupon);
        }
      );
    });
  };
  handleChange = (e, pId, var_name) => {
    // console.log(var_name, e.target.name, e.target.value);
    let value = e.target.value;
    if (var_name === "os_type_id") {
      this.renderVariantOptions(e.target.value, pId);
    }
    const foundProductObj = this.state.sampleArray.find(
      (product) => product.product_id === pId
    );
    const osId =
      var_name === "os_type_id"
        ? e.target.value
        : foundProductObj.variantArray[0].os_type_id;
    const osSortedObj = foundProductObj.variantArray.find(
      (item) => parseInt(item.os_type_id) === parseInt(osId)
    );

    // console.log(osSortedObj, osId);
    this.setState(
      {
        [var_name === "os_type_id"
          ? e.target.name
          : "variant_" + pId + "_osType"]:
          var_name === "os_type_id" ? e.target.value : osSortedObj.os_type_id,
        [var_name === "media_type_id"
          ? e.target.name
          : "variant_" + pId + "_media"]:
          var_name === "media_type_id"
            ? e.target.value
            : osSortedObj.media_type_id,
        [var_name === "view_and_validity_id"
          ? e.target.name
          : "variant_" + pId + "_view"]:
          var_name === "view_and_validity_id"
            ? e.target.value
            : osSortedObj.view_and_validity_id,
      },
      () => {
        // console.log(
        //   "media:" + this.state["variant_" + pId + "_media"],
        //   "os:" + this.state["variant_" + pId + "_osType"],
        //   "view:" + this.state["variant_" + pId + "_view"]
        // );
        let media = this.state["variant_" + pId + "_media"];
        let os = this.state["variant_" + pId + "_osType"];
        let view = this.state["variant_" + pId + "_view"];

        const foundVariantObj = foundProductObj.variantArray.find(
          (variant) =>
            parseInt(variant.os_type_id) === parseInt(os) &&
            parseInt(variant.media_type_id) === parseInt(media) &&
            parseInt(variant.view_and_validity_id) === parseInt(view)
        );

        // console.log("product", foundProductObj);
        // console.log("variant", foundVariantObj);
        if (foundVariantObj) {
          const new_price = foundVariantObj.offer_price;
          const total = foundVariantObj.offer_price * foundProductObj.quantity;
          let sampleArray = this.state.sampleArray;
          sampleArray.forEach((product) => {
            if (product.product_id === pId) {
              product["price"] = new_price;
              product["total"] = total;
            }
            // sampleArray.push(product);
          });
          // console.log(sampleArray);
          this.setState(
            {
              sampleArray,
            },
            () => {
              // console.log("sampleArray", this.state.sampleArray);
              let sub_total = 0;
              this.state.sampleArray.forEach((item) => {
                sub_total += parseInt(item.total);
                // console.log(sub_total);
              });
              this.setState(
                {
                  sub_total: Math.round((sub_total * 10) / 10),
                },
                () => {
                  // console.log(this.state.sub_total);
                }
              );
            }
          );
        }
        // } else {
        //   alert("Not Available");
        // }

        this.state.product_details.forEach((product) => {
          if (product.product_id === pId) {
            if (var_name === "os_type_id") {
              product.os_type_id = parseInt(value);
            }
            if (var_name === "media_type_id") {
              product.media_type_id = parseInt(value);
            }
            if (var_name === "view_and_validity_id") {
              product.view_and_validity_id = parseInt(value);
            }
          }
        });
        // console.log(this.state.product_details);
      }
    );
  };
  renderVariantOptions = (osId, pId) => {
    const foundProductObj = this.state.sampleArray.find(
      (item) => item.product_id === pId
    );
    const foundVariantArray = foundProductObj.variantArray.filter(
      (item) => parseInt(item.os_type_id) === parseInt(osId)
    );
    // console.log(foundVariantArray);

    const viewArray = [];
    const mediaArray = [];
    foundVariantArray.forEach((item) => {
      this.state.media.forEach((med) => {
        if (item.media_type_id === med.id) {
          mediaArray.push(med);
        }
      });
      this.state.view.forEach((viw) => {
        if (item.view_and_validity_id === viw.id) {
          viewArray.push(viw);
        }
      });
    });
    this.state.sampleArray.forEach((item) => {
      if (item.product_id === pId) {
        item.mediaArray = mediaArray;
        item.viewArray = viewArray;
      }
    });
    this.setState({ sampleArray: this.state.sampleArray });
    // this.setState({ view: viewArray, media: mediaArray }, () => {
    //   console.log("sampleArray", this.state.sampleArray);

    // console.log("viewArray", viewArray);
    // console.log("mediaArray", mediaArray);
    // });
  };
  newaddress = (e) => {
    let userToken = localStorage.getItem("token");
    console.log(userToken);
    const payload = {
      // name: this.state.name,
      // email: this.state.email,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      city: this.state.city,
      pincode: this.state.pincode,
      state: this.state.state,
      mobile: this.state.mobile,
    };
    // console.log(payload);
    axios
      .post(Constants.postUrls.updateAddress, payload, {
        headers: { Authorization: "Bearer " + userToken },
      })
      .then((res) => {
        console.log(res);
        this.setState(
          {
            // redirectAdd: true,
            shippingAddress: res.data,
            btnLoading: false,
          },
          () => {
            window.payLoad.shippingAddress = this.state.shippingAddress;
          }
        );
      });
  };

  placeOrder_old = (e) => {
    // let userToken = localStorage.getItem("token");
    // console.log(this.state.userDetails);
    // if (window.payLoad.address === 0) {
    //   this.setState({
    //     warn1: true,
    //   });
    // } else if (window.payLoad.payMode === "") {
    //   this.setState({
    //     warn2: true,
    //   });
    // } else {
    // console.log(this.state.grand_total);
    if (init() === "success") {
      if (this.state.grand_total != 0) {
        axios
          .post(Constants.postUrls.createOrderId, {
            amount: (
              this.state.grand_total -
              (this.state.coupon_amount || 0) * 100
            ).toString(),
          })
          .then((resp) => {
            this.setState(
              {
                orderId: resp.data,
              },
              () => {
                console.log(this.state.orderId);
                let options = {
                  // key: "rzp_live_rCkJJMueXcoaZV",
                  key: "rzp_live_rCkJJMueXcoaZV",
                  amount: this.state.grand_total,
                  name: "Ak Classes",
                  order_id: this.state.orderId,
                  description: "Ak Classes",
                  image: webAkLogo,
                  handler: function (response) {
                    console.log(response);
                    let token = localStorage.getItem("token");
                    console.log(token);
                    axios
                      .get(Constants.getUrls.user, {
                        headers: {
                          Authorization: "Bearer " + token,
                        },
                      })
                      .then((resp) => {
                        console.log(resp);
                        let winLoad = {
                          flag: "Website",
                          razorpay_order_id: response.razorpay_order_id,
                          razorpay_payment_id: response.razorpay_payment_id,
                          razorpay_signature: response.razorpay_signature,
                          contact_no: parseInt(resp.data.MOBILE),
                          address:
                            window.payLoad.shippingAddress === "selected"
                              ? window.payLoad.shippingAddress
                              : resp.data.ADDRESS_1,
                          // ,
                          // address: "",
                          cart: window.payLoad.cartId,
                          address_2: resp.data.ADDRESS_2,
                          grand_total: window.payLoad.grand_total
                            ? window.payLoad.grand_total === null
                              ? 0
                              : window.payLoad.grand_total
                            : window.payLoad.extendproductPrice === null
                            ? 0
                            : window.payLoad.extendproductPrice,
                          // grand_total: "",
                          last_name: resp.data?.LAST_NAME,
                          first_name: resp.data.FIRST_NAME,
                          mobile: parseInt(resp.data.MOBILE),
                          email: resp.data.email,
                          state: resp.data.STATE,

                          city: resp.data.CITY,
                          pincode: parseInt(resp.data.PINCODE),
                          country: "INDIA",
                          coupon: window.payLoad.coupon,
                          attempt: window.payLoad.attempt,
                          shipping: 0,
                          sku: window.payLoad.sku,
                          base_total: window.payLoad.grand_total
                            ? window.payLoad.grand_total === null
                              ? 0
                              : window.payLoad.grand_total
                            : window.payLoad.extendproductPrice === null
                            ? 0
                            : window.payLoad.extendproductPrice,
                          product_details: [
                            window.payLoad.product_detail_for_order,
                          ],
                        };
                        console.log("winload", winLoad);
                        axios
                          .post(Constants.postUrls.storeOrder, winLoad)
                          .then((res) => {
                            console.log("storeOrder", res);
                            if (res.data.status === "success") {
                              // window.location.href = "/payment-success";
                              this.setState({
                                redirect: true,
                              });
                            } else {
                              console.log("failed");
                              this.setState({
                                paymentError: true,
                              });
                            }
                          });
                      });
                  },
                  prefill: {
                    email: window.payLoad.email,
                    contact: "91" + window.payLoad.mobile,
                  },
                  theme: {
                    color: "#0f66b9",
                  },
                };
                let rzp = new window.Razorpay(options);
                rzp.open();
              }
            );
          });
      } else {
        window.location.href = "/order-placed";
      }
    }
  };

  placeOrder_26_3_24 = (e) => {
    console.log(localStorage.getItem("payment_gateway"));
    if (init() === "success") {
      console.log(this.state.userInfo.ADDRESS_1);
      console.log(this.state.address_1);
      axios.get(Constants.getUrls.user).then((respUser) => {
        console.log(respUser);
        if (
          respUser.data.FIRST_NAME === null ||
          respUser.data.email == null ||
          respUser.data.ADDRESS_1 === null ||
          respUser.data.CITY === null ||
          respUser.data.PINCODE === null ||
          respUser.data.STATE === null ||
          respUser.data.FIRST_NAME === "" ||
          respUser.data.email == "" ||
          respUser.data.ADDRESS_1 === "" ||
          respUser.data.CITY === "" ||
          respUser.data.PINCODE === "" ||
          respUser.data.STATE === ""
        ) {
          this.setState({
            placeOrderError: true,
            placeOrderMessage: "Please Fill Billing and Shipping.",
          });

          setTimeout(() => {
            this.setState({
              placeOrderError: false,
              placeOrderMessage: false,
            });
          }, 4000);
        } else {
          if (this.state.grand_total != 0) {
            axios
              .post(Constants.postUrls.createOrderId, {
                amount: (
                  this.state.grand_total -
                  (this.state.coupon_amount || 0) * 100
                ).toString(),
              })
              .then((respCreate) => {
                this.setState(
                  {
                    orderId: respCreate.data,
                  },
                  () => {
                    console.log(respCreate);

                    let winLoad = {
                      flag: "Website",
                      // razorpay_order_id: response.razorpay_order_id,
                      // razorpay_payment_id: response.razorpay_payment_id,
                      // razorpay_signature: response.razorpay_signature,
                      contact_no: parseInt(respUser.data.MOBILE),
                      address:
                        window.payLoad.shippingAddress === "selected"
                          ? window.payLoad.shippingAddress
                          : respUser.data.ADDRESS_1,
                      // ,
                      // address: "",
                      cart: window.payLoad.cartId,
                      address_2: respUser.data.ADDRESS_2,
                      grand_total: window.payLoad.grand_total
                        ? window.payLoad.grand_total === null
                          ? 0
                          : window.payLoad.grand_total
                        : window.payLoad.extendproductPrice === null
                        ? 0
                        : window.payLoad.extendproductPrice,
                      // grand_total: "",
                      last_name: respUser.data?.LAST_NAME,
                      first_name: respUser.data.FIRST_NAME,
                      mobile: parseInt(respUser.data.MOBILE),
                      email: respUser.data.email,
                      state: respUser.data.STATE,

                      city: respUser.data.CITY,
                      pincode: parseInt(respUser.data.PINCODE),
                      country: "INDIA",
                      coupon: window.payLoad.coupon,
                      attempt: window.payLoad.attempt,
                      shipping: 0,
                      sku: window.payLoad.sku,
                      base_total: window.payLoad.grand_total
                        ? window.payLoad.grand_total === null
                          ? 0
                          : window.payLoad.grand_total
                        : window.payLoad.extendproductPrice === null
                        ? 0
                        : window.payLoad.extendproductPrice,
                      product_details: [
                        window.payLoad.product_detail_for_order,
                      ],
                    };
                    console.log("winload", winLoad);
                    axios
                      .post(Constants.postUrls.storeOrder, winLoad)
                      .then((res) => {
                        console.log("storeOrder", res.data[0].ID);
                        if (res.data.status === "success") {
                          // window.location.href = "/payment-success";
                          this.setState(
                            {
                              redirect: true,
                              storedOrderId: res.data[0].ID,
                            },
                            () => {
                              console.log(this.state.storedOrderId);
                              let payment_gateway =
                                localStorage.getItem("payment_gateway");
                              // console.log(payment_gateway);
                              if (payment_gateway === "razorpay") {
                                let options = {
                                  // key: "rzp_live_rCkJJMueXcoaZV",
                                  key: "rzp_live_rCkJJMueXcoaZV",
                                  amount: this.state.grand_total,
                                  name: "Ak Classes",
                                  order_id: this.state.orderId,
                                  description: "Ak Classes",
                                  image: webAkLogo,
                                  handler: function (response) {
                                    console.log(response);
                                    let token = localStorage.getItem("token");
                                    console.log(token);
                                    axios
                                      .get(Constants.getUrls.user, {
                                        headers: {
                                          Authorization: "Bearer " + token,
                                        },
                                      })
                                      .then((resp) => {
                                        console.log(resp);

                                        let payloadStatus = {
                                          ord_id: res.data[0].ID,
                                          payment_id:
                                            response.razorpay_payment_id,
                                          signature:
                                            response.razorpay_signature,
                                          order_no: response.razorpay_order_id,
                                        };
                                        axios
                                          .post(
                                            Constants.postUrls.paymentStatus,
                                            payloadStatus
                                          )
                                          .then((resStatus) => {
                                            console.log(resStatus);
                                          });
                                      });
                                  },
                                  prefill: {
                                    email: window.payLoad.email,
                                    contact: "91" + window.payLoad.mobile,
                                  },
                                  theme: {
                                    color: "#0f66b9",
                                  },
                                };
                                let rzp = new window.Razorpay(options);
                                rzp.open();
                              } else if (payment_gateway === "phonepe") {
                                let payload = {
                                  phonepe_amount:
                                    localStorage.getItem("phonepe_amount"),
                                  user_id: localStorage.getItem("user_id"),
                                  mobile_number:
                                    localStorage.getItem("mobile_number"),
                                  //redirect_url: 'https://akclasses.in/dashboard',
                                  //callback_url: 'https://admin.akclasses.in/api/phonepe_callback',
                                };
                                axios
                                  .post(Constants.postUrls.phonepe, payload)
                                  .then((resPe) => {
                                    console.log(resPe);
                                    if (resPe.data.success === true) {
                                      window.location.href =
                                        resPe.data.data.instrumentResponse.redirectInfo.url;
                                      // let payloadStatus = {
                                      //   ord_id: res.data[0].ID,
                                      //   payment_id: "",
                                      //   signature: "",
                                      //   order_no: "",
                                      // }
                                      // axios.post(Constants.postUrls.paymentStatus, payloadStatus).then((resStatus) => {
                                      //   console.log(resStatus);
                                      // });
                                    }
                                  });
                              }
                            }
                          );
                        } else {
                          console.log("failed");
                          this.setState({
                            paymentError: true,
                          });
                        }
                      });
                  }
                );
              });
          } else {
            window.location.href = "/order-placed";
          }
        }
      });
    } else {
      this.setState({
        placeOrderError: true,
        placeOrderMessage: "Please Login To Continue.",
      });
      setTimeout(() => {
        this.setState({
          placeOrderError: false,
          placeOrderMessage: false,
        });
      }, 4000);
    }
  };

  // placeOrder = (e) => {
  //   console.log(localStorage.getItem("payment_gateway"));
  //   // return console.log(window.payLoad);
  //   if (init() === "success") {
  //     console.log(this.state.userInfo.ADDRESS_1);
  //     console.log(this.state.address_1);
  //     axios.get(Constants.getUrls.user).then((resp) => {
  //       console.log(resp);
  //       if (
  //         resp.data.FIRST_NAME === null ||
  //         resp.data.email == null ||
  //         resp.data.ADDRESS_1 === null ||
  //         resp.data.CITY === null ||
  //         resp.data.PINCODE === null ||
  //         resp.data.STATE === null ||
  //         resp.data.FIRST_NAME === "" ||
  //         resp.data.email == "" ||
  //         resp.data.ADDRESS_1 === "" ||
  //         resp.data.CITY === "" ||
  //         resp.data.PINCODE === "" ||
  //         resp.data.STATE === ""
  //       ) {
  //         this.setState({
  //           placeOrderError: true,
  //           placeOrderMessage: "Please Fill Billing and Shipping.",
  //         });

  //         setTimeout(() => {
  //           this.setState({
  //             placeOrderError: false,
  //             placeOrderMessage: false,
  //           });
  //         }, 4000);
  //       } else {
  //         if (this.state.grand_total != 0) {
  //           let payloadCreate = {
  //             flag: "Website",
  //             contact_no: parseInt(resp.data.MOBILE),
  //             address:
  //               window.payLoad.shippingAddress === "selected"
  //                 ? window.payLoad.shippingAddress
  //                 : resp.data.ADDRESS_1,
  //             // ,
  //             // address: "",
  //             cart: window.payLoad.cartId,
  //             address_2: resp.data.ADDRESS_2,
  //             grand_total:
  //               this.state.courseDetails.TYPE === "BOOKS"
  //                 ? this.state.courseDetails.PRICE -
  //                   (this.state.coupon_amount || 0)
  //                 : window.payLoad.grand_total
  //                 ? window.payLoad.grand_total === null
  //                   ? 0
  //                   : window.payLoad.grand_total
  //                 : window.payLoad.extendproductPrice === null
  //                 ? 0
  //                 : window.payLoad.extendproductPrice,
  //             // grand_total: "",
  //             last_name: resp.data?.LAST_NAME,
  //             first_name: resp.data.FIRST_NAME,
  //             mobile: parseInt(resp.data.MOBILE),
  //             email: resp.data.email,
  //             state: resp.data.STATE,
  //             city: resp.data.CITY,
  //             pincode: parseInt(resp.data.PINCODE),
  //             country: "INDIA",
  //             coupon: window.payLoad.coupon,
  //             attempt: window.payLoad.attempt,
  //             shipping: 0,
  //             base_total:
  //               this.state.courseDetails.TYPE === "BOOKS"
  //                 ? this.state.courseDetails.PRICE -
  //                   (this.state.coupon_amount || 0)
  //                 : window.payLoad.grand_total
  //                 ? window.payLoad.grand_total === null
  //                   ? 0
  //                   : window.payLoad.grand_total
  //                 : window.payLoad.extendproductPrice === null
  //                 ? 0
  //                 : window.payLoad.extendproductPrice,
  //             amount:
  //               this.state.courseDetails.TYPE === "BOOKS"
  //                 ? (
  //                     (this.state.courseDetails.PRICE -
  //                       (this.state.coupon_amount || 0)) *
  //                     100
  //                   ).toString()
  //                 : (
  //                     ((this.props.base_total
  //                       ? this.props.base_total
  //                       : this.props.courseDetails?.PRICE) -
  //                       (this.state.coupon_amount || 0)) *
  //                     100
  //                   ).toString(),
  //             product_details: [window.payLoad.product_detail_for_order],
  //           };
  //           console.log(payloadCreate);
  //           this.setState({ isLoading: true });


  //           axios
  //             .post(Constants.postUrls.createOrderId, payloadCreate)
  //             .then((respcreate) => {
  //               // condition for close loading place order
  //               if(respcreate){
  //           this.setState({ isLoading: false });

  //               }
  //               this.setState(
  //                 {
  //                   orderId: respcreate.data.orderId,
  //                 },
  //                 () => {
  //                   console.log(respcreate);
  //                   let payment_gateway =
  //                     localStorage.getItem("payment_gateway");
  //                   console.log(payment_gateway);
  //                   if (
  //                     payment_gateway === "razorpay" ||
  //                     payment_gateway == "null" ||
  //                     payment_gateway == "undefined"
  //                   ) {
  //                     let options = {
  //                       // key: "rzp_live_rCkJJMueXcoaZV",
  //                       key: "rzp_live_rCkJJMueXcoaZV",
  //                       amount:
  //                         this.state.courseDetails.TYPE === "BOOKS"
  //                           ? this.state.courseDetails.PRICE
  //                           : this.state.grand_total,
  //                       name: "Ak Classes",
  //                       order_id: this.state.orderId,
  //                       description: "Ak Classes",
  //                       image: webAkLogo,
  //                       handler: function (response) {
  //                         console.log(response);
  //                         let token = localStorage.getItem("token");
  //                         console.log(token);
  //                         axios
  //                           .get(Constants.getUrls.user, {
  //                             headers: {
  //                               Authorization: "Bearer " + token,
  //                             },
  //                           })
  //                           .then((resp) => {
  //                             console.log(resp);
  //                             let winLoad = {
  //                               flag: "Website",
  //                               razorpay_order_id: response.razorpay_order_id,
  //                               razorpay_payment_id:
  //                                 response.razorpay_payment_id,
  //                               razorpay_signature: response.razorpay_signature,
  //                               contact_no: parseInt(resp.data.MOBILE),
  //                               address:
  //                                 window.payLoad.shippingAddress === "selected"
  //                                   ? window.payLoad.shippingAddress
  //                                   : resp.data.ADDRESS_1,
  //                               // ,
  //                               // address: "",
  //                               cart: window.payLoad.cartId,
  //                               address_2: resp.data.ADDRESS_2,
  //                               grand_total:
  //                                 window.payLoad.courseDetails?.TYPE === "BOOKS"
  //                                   ? window.payLoad.courseDetails.PRICE
  //                                   : window.payLoad.grand_total
  //                                   ? window.payLoad.grand_total === null
  //                                     ? 0
  //                                     : window.payLoad.grand_total
  //                                   : window.payLoad.extendproductPrice === null
  //                                   ? 0
  //                                   : window.payLoad.extendproductPrice,
  //                               // grand_total: "",
  //                               last_name: resp.data?.LAST_NAME,
  //                               first_name: resp.data.FIRST_NAME,
  //                               mobile: parseInt(resp.data.MOBILE),
  //                               email: resp.data.email,
  //                               state: resp.data.STATE,

  //                               city: resp.data.CITY,
  //                               pincode: parseInt(resp.data.PINCODE),
  //                               country: "INDIA",
  //                               coupon: window.payLoad.coupon,
  //                               attempt: window.payLoad.attempt,
  //                               shipping: 0,
  //                               sku: window.payLoad.sku,
  //                               createOrderId: respcreate.data.details.ID,
  //                               base_total:
  //                                 window.payLoad.courseDetails?.TYPE === "BOOKS"
  //                                   ? window.payLoad.courseDetails.PRICE
  //                                   : window.payLoad.grand_total
  //                                   ? window.payLoad.grand_total === null
  //                                     ? 0
  //                                     : window.payLoad.grand_total
  //                                   : window.payLoad.extendproductPrice === null
  //                                   ? 0
  //                                   : window.payLoad.extendproductPrice,
  //                               product_details: [
  //                                 window.payLoad.product_detail_for_order,
  //                               ],
  //                             };
  //                             console.log("winload", winLoad);
  //                             axios
  //                               .post(Constants.postUrls.storeOrder, winLoad)
  //                               .then((res) => {
  //                                 console.log("storeOrder", res);
  //                                 if (res.data.status === "success") {
  //                                   window.location.href = "/dashboard";
  //                                   // this.setState({
  //                                   //   redirect: true,
  //                                   // });
  //                                   this.setState({ isLoading: false });
  //                                 } else {
  //                                   console.log("failed");
  //                                   this.setState({
  //                                     paymentError: true,
  //                                   });
  //                                   this.setState({ isLoading: false });
  //                                 }
  //                               });
  //                           });
  //                       },
  //                       prefill: {
  //                         email: window.payLoad.email,
  //                         contact: "91" + window.payLoad.mobile,
  //                       },
  //                       theme: {
  //                         color: "#0f66b9",
  //                       },
  //                     };
  //                     let rzp = new window.Razorpay(options);
  //                     rzp.open();
  //                   } else if (payment_gateway === "phonepe") {
  //                     let payload = {
  //                       phonepe_amount: localStorage.getItem("phonepe_amount"),
  //                       user_id: localStorage.getItem("user_id"),
  //                       mobile_number: localStorage.getItem("mobile_number"),
  //                       redirect_url: "https://akclasses.in/dashboard",
  //                       callback_url:
  //                         "https://admin.akclasses.in/api/phonepe_callback",
  //                     };
  //                     axios
  //                       .post(Constants.postUrls.phonepe, payload)
  //                       .then((resPe) => {
  //                         console.log(resPe);
  //                         if (resPe.data.success === true) {
  //                           window.location.href =
  //                             resPe.data.data.instrumentResponse.redirectInfo.url;
  //                           // let payloadStatus = {
  //                           //   ord_id: res.data[0].ID,
  //                           //   payment_id: "",
  //                           //   signature: "",
  //                           //   order_no: "",
  //                           // }
  //                           // axios.post(Constants.postUrls.paymentStatus, payloadStatus).then((resStatus) => {
  //                           //   console.log(resStatus);
  //                           // });
  //                         }
  //                       });
  //                   }
  //                 }
  //               );
  //             });
  //         }
  //         else {
  //           window.location.href = "/order-placed";
  //         }
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       placeOrderError: true,
  //       placeOrderMessage: "Please Login To Continue.",
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         placeOrderError: false,
  //         placeOrderMessage: false,
  //       });
  //     }, 4000);
  //   }
  // };

  placeOrder = (e) => {
    console.log(localStorage.getItem("payment_gateway"));
    // return console.log(window.payLoad);
    if (init() === "success") {
      console.log(this.state.userInfo.ADDRESS_1);
      console.log(this.state.address_1);
      axios.get(Constants.getUrls.user).then((resp) => {
        console.log(resp);
        if (
          resp.data.FIRST_NAME === null ||
          resp.data.email == null ||
          resp.data.ADDRESS_1 === null ||
          resp.data.CITY === null ||
          resp.data.PINCODE === null ||
          resp.data.STATE === null ||
          resp.data.FIRST_NAME === "" ||
          resp.data.email == "" ||
          resp.data.ADDRESS_1 === "" ||
          resp.data.CITY === "" ||
          resp.data.PINCODE === "" ||
          resp.data.STATE === ""
        ) {
          this.setState({
            placeOrderError: true,
            placeOrderMessage: "Please Fill Billing and Shipping.",
          });
  
          setTimeout(() => {
            this.setState({
              placeOrderError: false,
              placeOrderMessage: false,
            });
          }, 4000);
        } else {
          if (this.state.grand_total != 0) {
            let payloadCreate = {
              flag: "Website",
              contact_no: parseInt(resp.data.MOBILE),
              address:
                window.payLoad.shippingAddress === "selected"
                  ? window.payLoad.shippingAddress
                  : resp.data.ADDRESS_1,
              cart: window.payLoad.cartId,
              address_2: resp.data.ADDRESS_2,
              grand_total:
                this.state.courseDetails.TYPE === "BOOKS"
                  ? this.state.courseDetails.PRICE -
                    (this.state.coupon_amount || 0)
                  : window.payLoad.grand_total
                  ? window.payLoad.grand_total === null
                    ? 0
                    : window.payLoad.grand_total
                  : window.payLoad.extendproductPrice === null
                  ? 0
                  : window.payLoad.extendproductPrice,
              last_name: resp.data?.LAST_NAME,
              first_name: resp.data.FIRST_NAME,
              mobile: parseInt(resp.data.MOBILE),
              email: resp.data.email,
              state: resp.data.STATE,
              city: resp.data.CITY,
              pincode: parseInt(resp.data.PINCODE),
              country: "INDIA",
              coupon: window.payLoad.coupon,
              attempt: window.payLoad.attempt,
              shipping: 0,
              base_total:
                this.state.courseDetails.TYPE === "BOOKS"
                  ? this.state.courseDetails.PRICE -
                    (this.state.coupon_amount || 0)
                  : window.payLoad.grand_total
                  ? window.payLoad.grand_total === null
                    ? 0
                    : window.payLoad.grand_total
                  : window.payLoad.extendproductPrice === null
                  ? 0
                  : window.payLoad.extendproductPrice,
              amount:
                this.state.courseDetails.TYPE === "BOOKS"
                  ? (
                      (this.state.courseDetails.PRICE -
                        (this.state.coupon_amount || 0)) *
                      100
                    ).toString()
                  : (
                      ((this.props.base_total
                        ? this.props.base_total
                        : this.props.courseDetails?.PRICE) -
                        (this.state.coupon_amount || 0)) *
                      100
                    ).toString(),
              product_details: [window.payLoad.product_detail_for_order],
            };
            console.log(payloadCreate);
            this.setState({ isLoading: true });
  
            axios
              .post(Constants.postUrls.createOrderId, payloadCreate)
              .then((respcreate) => {
                // condition for close loading place order
                if (respcreate) {
                  this.setState({ isLoading: false });
                }
                this.setState(
                  {
                    orderId: respcreate.data.orderId,
                  },
                  () => {
                    console.log(respcreate);
                    let payment_gateway = localStorage.getItem("payment_gateway");
                    console.log(payment_gateway);
                    if (
                      payment_gateway === "razorpay" ||
                      payment_gateway == "null" ||
                      payment_gateway == "undefined"
                    ) {
                      let options = {
                        key: "rzp_live_rCkJJMueXcoaZV",
                        amount:
                          this.state.courseDetails.TYPE === "BOOKS"
                            ? this.state.courseDetails.PRICE
                            : this.state.grand_total,
                        name: "Ak Classes",
                        order_id: this.state.orderId,
                        description: "Ak Classes",
                        image: webAkLogo,
                        handler: function (response) {
                          console.log(response);
                          let token = localStorage.getItem("token");
                          console.log(token);
                          axios
                            .get(Constants.getUrls.user, {
                              headers: {
                                Authorization: "Bearer " + token,
                              },
                            })
                            .then((resp) => {
                              console.log(resp);
                              let winLoad = {
                                flag: "Website",
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_payment_id:
                                  response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                                contact_no: parseInt(resp.data.MOBILE),
                                address:
                                  window.payLoad.shippingAddress === "selected"
                                    ? window.payLoad.shippingAddress
                                    : resp.data.ADDRESS_1,
                                cart: window.payLoad.cartId,
                                address_2: resp.data.ADDRESS_2,
                                grand_total:
                                  window.payLoad.courseDetails?.TYPE === "BOOKS"
                                    ? window.payLoad.courseDetails.PRICE
                                    : window.payLoad.grand_total
                                    ? window.payLoad.grand_total === null
                                      ? 0
                                      : window.payLoad.grand_total
                                    : window.payLoad.extendproductPrice === null
                                    ? 0
                                    : window.payLoad.extendproductPrice,
                                last_name: resp.data?.LAST_NAME,
                                first_name: resp.data.FIRST_NAME,
                                mobile: parseInt(resp.data.MOBILE),
                                email: resp.data.email,
                                state: resp.data.STATE,
                                city: resp.data.CITY,
                                pincode: parseInt(resp.data.PINCODE),
                                country: "INDIA",
                                coupon: window.payLoad.coupon,
                                attempt: window.payLoad.attempt,
                                shipping: 0,
                                sku: window.payLoad.sku,
                                createOrderId: respcreate.data.details.ID,
                                base_total:
                                  window.payLoad.courseDetails?.TYPE === "BOOKS"
                                    ? window.payLoad.courseDetails.PRICE
                                    : window.payLoad.grand_total
                                    ? window.payLoad.grand_total === null
                                      ? 0
                                      : window.payLoad.grand_total
                                    : window.payLoad.extendproductPrice === null
                                    ? 0
                                    : window.payLoad.extendproductPrice,
                                product_details: [
                                  window.payLoad.product_detail_for_order,
                                ],
                              };
                              console.log("winload", winLoad);
                              axios
                                .post(Constants.postUrls.storeOrder, winLoad)
                                .then((res) => {
                                  console.log("storeOrder", res);
                                  if (res.data.status === "success") {
                                    window.location.href = "/dashboard";
                                    this.setState({ isLoading: false });
                                  } else {
                                    console.log("failed");
                                    this.setState({
                                      paymentError: true,
                                    });
                                    this.setState({ isLoading: false });
                                  }
                                });
                            });
                        },
                        prefill: {
                          email: window.payLoad.email,
                          contact: "91" + window.payLoad.mobile,
                        },
                        theme: {
                          color: "#0f66b9",
                        },
                      };
                      let rzp = new window.Razorpay(options);
                      rzp.open();
                    } else if (payment_gateway === "phonepe") {
                      let payload = {
                        phonepe_amount: localStorage.getItem("phonepe_amount"),
                        user_id: localStorage.getItem("user_id"),
                        mobile_number: localStorage.getItem("mobile_number"),
                        redirect_url: "https://akclasses.in/dashboard",
                        callback_url:
                          "https://admin.akclasses.in/api/phonepe_callback",
                      };
                      axios
                        .post(Constants.postUrls.phonepe, payload)
                        .then((resPe) => {
                          console.log(resPe);
                          if (resPe.data.success === true) {
                            window.location.href =
                              resPe.data.data.instrumentResponse.redirectInfo.url;
                          }
                        });
                    }
                  }
                );
              })
              .catch((error) => {
                // Handle error here
                console.error("Error creating order:", error);
                this.setState({ isOrderLoading: true });
                // Optionally, you can also display a message to the user if needed
                this.setState({
                  placeOrderError: true,
                  placeOrderMessage: "There was an issue creating the order. Please try again.",
                });
                setTimeout(() => {
                  this.setState({
                    placeOrderError: false,
                    placeOrderMessage: false,
                  });
                }, 4000);
              });
          } else {
            window.location.href = "/order-placed";
          }
        }
      });
    } else {
      this.setState({
        placeOrderError: true,
        placeOrderMessage: "Please Login To Continue.",
      });
      setTimeout(() => {
        this.setState({
          placeOrderError: false,
          placeOrderMessage: false,
        });
      }, 4000);
    }
  };
  

  renderAddressForm = () => {
    this.setState({ showAddressForm: true });
  };
  showshippingForm() {
    this.setState({
      showshippingForm: !this.state.showshippingForm,
    });
  }
  showLogin() {
    this.setState({
      showLoginForm: !this.state.showLoginForm,
    });
  }
  EditAddress() {
    console.log(this.state.userDetails);
    console.log(this.state.userInfo);
    console.log(this.state.FIRST_NAME);
    console.log(this.state.EMAIL);
    this.setState({
      editAdd: !this.state.editAdd,
    });
  }
  onUpdate = () => {
    console.log(this.state.userInfo.FIRST_NAME);
    console.log(this.state.updateName);
    console.log(this.state.updateEmail);
    console.log(this.state.userInfo.EMAIL);
    console.log(this.state.email);
    console.log(this.state.mobile);
    console.log(this.state.name);
    this.setState({
      btnLoading: true,
      // showNotif: true,
      // notiMessage: "Address updated..."
    });
    let userToken = localStorage.getItem("token");
    const payload = {
      name: this.state.name ? this.state.name : this.state.userInfo.FIRST_NAME,
      email: this.state.email ? this.state.email : this.state.userInfo.email,
      mobile: this.state.mobile,
      city: this.state.city,
      state: this.state.state,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      pincode: this.state.pincode,
    };
    console.log(payload);
    axios
      .post(Constants.postUrls.updateAddress, payload, {
        headers: { Authorization: "Bearer " + userToken },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          this.setState({
            // redirect: true,
            // showNotif: true,
            btnLoading: false,
            userInfo: this.state.userInfo,
            userDetails: this.state.userInfo,
            grand_total: this.state.grand_total,
            address_1: res.data.data.ADDRESS_1,
            address_2: res.data.data.ADDRESS_2,
            city: res.data.data.CITY,
            state: res.data.data.STATE,
            pincode: res.data.data.PINCODE,
            mobile: res.data.data.MOBILE,
            updateName: res.data.data.FIRST_NAME,
            updateEmail: res.data.data.email,
            editAdd: false,
            editCompleteMsg: true,
            accountDetails: true,
          });
          setTimeout(() => {
            this.setState({
              editCompleteMsg: false,
            });
            window.location.reload();
          }, 3000);
        }
      });
  };

  validateMobile2(text) {
    const reg = /^[0]?[789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNo: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNo: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }

  verifyOTP = (e) => {
    if (this.state.otp.length > 6) {
      e.preventDefault();
      this.setState({
        showError: true,
        message: "Enter Valid OTP of 6 digits",
      });
    } else {
      e.preventDefault();
      this.setState({ loader: true });
      const Type = this.state.from;
      console.log(this.state.Type);

      let ApiUrl =
        Type === "Login"
          ? Constants.postUrls.verifyOTP
          : Constants.postUrls.verifyRegisterOTP;
      let payload;

      payload = {
        mobile: this.state.mobileNo,
        otp: this.state.otp,
      };
      console.log(payload);

      axios.post(ApiUrl, payload).then((res) => {
        console.log("login", res);
        if (res.data.status === "success") {
          localStorage.setItem("token", res.data.token);
          sessionStorage.setItem("token", res.data.token);
          if (init() === "success") {
            if (res.data.user.FIRST_NAME === "") {
              this.setState({
                showRegister: false,
                loginScreen: false,
                billingshipping: true,
                userId: res.data.user.ID,
                loader: false,
              });
              // this.updateUserDetails();
            } else {
              this.setState({
                placeOrderButton: true,
                userDetails: res.data.user,
                message: res.data.message,
                showLogin: false,
                OTPModal: false,
                loader: false,
                userId: res.data.user.ID,
                loginScreen: false,
                billingshipping: false,
                accountDetails: true,
              });
              this.getUserInfo();
               window.location.reload()
              // this.placeOrder();
            }

            // var frm = document.getElementById("loginForm");
            // frm.reset();
            // window.location.href = "/";
          } else {
            alert("Something went wrong");
          }
        } else {
          this.setState({
            showError: true,
            message: res.data.message,
            btnLoading: false,
            showNotif: false,
            loader: false,
          });
          var frm = document.getElementById("loginForm");
          setTimeout(() => {
            this.setState({ showError: false });

            frm.reset();
          }, 2000);
        }
      }).catch((error) => {
        if (error.response && error.response.status === 500) {
          this.setState({
            notifMsg: "Something went wrong while login",
            loader: false,
          });
        } else {
          this.setState({
            showError: true,
            message: "An error occurred. Please try again.",
            loader: false,
          });
        }
      });
  }
};

  validateOTP(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState({
        otpValidate: false,
        otp: text,
        showOtpError: true,
      });
      return false;
    } else {
      this.setState({
        otpValidate: true,
        otp: text,
        message: "",
        showOtpError: false,
      });
      return true;
    }
  }

  handleGoToDashboard = () => {
    // Redirect to /dashboard
    window.location.href = '/dashboard';
};

  render() {
    if (this.state.redirect) {
      return (
        <Navigate
          to={{
            pathname: "/dashboard",
            orderId: this.state.orderId,
          }}
        />
      );
    } else if (this.state.redirectToaddress) {
      return <Navigate to={{ pathname: "/userdetails/orders" }} />;
    } else if (this.state.success) {
      return (
        <Navigate
          to={{
            pathname: "/order-detail/" + this.state.cId,
            state: { data: this.state.oData },
          }}
        />
      );
    } else {
      return (
        <>
          <section
            className={
              window.innerWidth < 768
                ? "section1CheckOutResp"
                : "section1CheckOut"
            }
          >
            <h1 className="fonts2">Checkout</h1>
          </section>
          <section
            className="section-b-space"
            style={{ paddingTop: "16px", backgroundColor: "#f5f4f4" }}
          >
            <div className="container contain2 padding-cls">
              <div className="checkout-page">
                <div className="checkout-form">
                  <div className="checkout">
                    {window.innerWidth < 768 ? (
                      <div className="row resp">
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                          {this.state.billingshipping ? (
                            <div className="bilship">
                              <div
                                className="billingblueback"
                                onClick={() => {
                                  this.setState({
                                    billingshipping: false,
                                    accountDetails: false,
                                    loginScreen: true,
                                  });
                                }}
                              >
                                <span className="alreadyreg">
                                  Already registered? Click here to login{" "}
                                </span>
                              </div>
                              <div className="checkout-title ">
                                <div className="row">
                                  <div className="col-md-7">
                                    <h3>Billing and Shippingss</h3>
                                  </div>
                                </div>
                              </div>
                              <form
                                onSubmit={(e) => {
                                  this.registerUser(e);
                                }}
                              >
                                <div className="">
                                  <div className="row check-out">
                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{
                                        position: "relative",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <div className="namefield">
                                        Name{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </div>
                                      <input
                                        type="text"
                                        className="nameinput"
                                        name="FIRST_NAME"
                                        placeholder="Full name"
                                        value={this.state.FIRST_NAME}
                                        required
                                        onChange={(e) => {
                                          this.setState(
                                            { FIRST_NAME: e.target.value },
                                            () => {
                                              this.validateName(
                                                this.state.FIRST_NAME
                                              );
                                            }
                                          );
                                        }}
                                      />
                                      {this.state.showNameError &&
                                      this.state.FIRST_NAME.length > 0 ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: 10,
                                          }}
                                        >
                                          Enter valid name
                                        </p>
                                      ) : null}
                                    </div>
                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <label className="namefield">
                                        {" "}
                                        Address{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="nameinput"
                                        name="address_1"
                                        value={this.state.address_1}
                                        required
                                        onChange={(e) => {
                                          this.handleInput(e);
                                        }}
                                        placeholder="Enter your full address"
                                      />
                                    </div>
                                    {/* <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <input
                                        type="text"
                                        className="nameinput"
                                        name="address_2"
                                        required={true}
                                        placeholder="Apartment ,suite ,unit etc.(optional)"
                                        value={this.state.address_2}
                                        onChange={(e) => {
                                          this.handleInput(e);
                                        }}
                                      />
                                    </div> */}
                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <div
                                        className="form-group col-md-6 col-sm-6 col-xs-6"
                                        style={{ left: "-13px" }}
                                      >
                                        <label className="namefield">
                                          Town / City{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="cityinput"
                                          name="city"
                                          placeholder="Ex. Nagpur"
                                          value={this.state.city}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { city: e.target.value },
                                              () => {
                                                this.validateCity(
                                                  this.state.city
                                                );
                                              }
                                            );
                                          }}
                                        />
                                        {this.state.showCityError &&
                                        this.state.city.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid City Name
                                          </p>
                                        ) : null}
                                      </div>
                                      <div
                                        className="form-group col-md-6 col-sm-6 col-xs-6"
                                        style={{ left: "-8px" }}
                                      >
                                        <label className="namefield">
                                          State{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="cityinput"
                                          name="state"
                                          placeholder="Ex. Maharashtra"
                                          value={this.state.state}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { state: e.target.value },
                                              () => {
                                                this.validateState(
                                                  this.state.state
                                                );
                                              }
                                            );
                                          }}
                                        />
                                        {this.state.showStateError &&
                                        this.state.state.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid State
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{ top: "-23px" }}
                                    >
                                      <label className="namefield">
                                        Pincode{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="nameinput"
                                        name="pincode"
                                        maxLength={6}
                                        value={this.state.pincode}
                                        required
                                        onChange={(e) => {
                                          this.setState({
                                            pincode: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{ top: "-23px" }}
                                    >
                                      <label className="namefield">
                                        Phone{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </label>
                                      <input
                                        type="number"
                                        className="nameinput"
                                        name="mobile"
                                        value={this.state.mobile}
                                        required
                                        maxLength={10}
                                        onChange={(e) => {
                                          this.setState(
                                            { mobile: e.target.value },
                                            () => {
                                              this.validateMobile(
                                                this.state.mobile
                                              );
                                            }
                                          );
                                        }}
                                      />
                                      {this.state.showmobileError &&
                                      this.state.mobile.length > 0 ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: 10,
                                            position: "relative",
                                            left: "35px",
                                          }}
                                        >
                                          Enter valid mobile
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="row check-out">
                                  <div
                                    className="form-group col-md-12 col-sm-12 col-xs-12"
                                    style={{ top: "-23px" }}
                                  >
                                    <div className="namefield">
                                      Email address{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </div>
                                    <input
                                      type="email"
                                      name="EMAIL"
                                      className="nameinput"
                                      value={this.state.EMAIL}
                                      required
                                      onChange={(e) => {
                                        this.setState(
                                          { EMAIL: e.target.value },
                                          () => {
                                            this.validateEmail(
                                              this.state.EMAIL
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    {this.state.showEmailError &&
                                    this.state.EMAIL.length > 0 ? (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: 10,
                                          position: "relative",
                                          left: "35px",
                                        }}
                                      >
                                        Enter valid e-mail address
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                {this.state.showError ? (
                                  <div>
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: 14,
                                        position: "relative",
                                        marginBottom: "23px",
                                      }}
                                    >
                                      {this.state.showErrMsg}
                                    </p>
                                  </div>
                                ) : null}
                                <button
                                  to=""
                                  type="submit"
                                  className="placeorders2"
                                  style={{
                                    textTransform: "capitalize",
                                    top: "-25px",
                                  }}
                                >
                                  {this.state.btnLoading ? (
                                    <Lottie
                                      options={{
                                        animationData: loader,
                                      }}
                                      style={{
                                        width: "50px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    "create Account & Place Order"
                                  )}
                                </button>
                                {/* <div className="placeorders2" onClick={() => {
                                    this.setState({
                                      editAdd: false,
                                      accountDetails: true,
                                      defaultPage: false,
                                      regForm: false,
                                    })
                                  }}>
                                  <div className="ordbtn2">Create Account & Place Order</div>
                                </div> */}
                              </form>
                            </div>
                          ) : this.state.loginScreen ? (
                            <>
                              <div
                                style={{
                                  background: "#fff",

                                  padding: "25px",
                                  boxShadow:
                                    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                  marginBottom: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <div className="checkout-title">
                                  <div className="row">
                                    <h4 className="logintoaccResp">
                                      Login to your account{" "}
                                    </h4>
                                    {/* <div className="col-md-6"> */}
                                    <p className="h3Login">
                                      If you have shopped with us before, please
                                      enter your details below. If you are a new
                                      customer, please proceed to the Billing
                                      section.
                                    </p>
                                  </div>
                                </div>
                                <form
                                  onSubmit={(e) => {
                                    if (this.state.OTPModal) {
                                      this.verifyOTP(e);
                                    } else {
                                      this.loginUserOTP(e);
                                    }
                                  }}
                                >
                                  <div className="check-out checkoutpageLogin">
                                    <div className="">
                                      <div className="row">
                                        <div className="form-group inputWidth col-sm-6 col-md-12">
                                          <div
                                            className="bottomSpace2"
                                            style={{
                                              position: "relative",
                                              top: "10px",
                                            }}
                                          >
                                            <label
                                              htmlFor=""
                                              className="phnmbrResp"
                                            >
                                              Phone Number
                                            </label>
                                            <input
                                              name="mobileNo"
                                              className="nameinputTagResp"
                                              type="number"
                                              required
                                              placeholder="10-digit Number"
                                              onChange={(e) => {
                                                this.setState(
                                                  {
                                                    mobileNo: e.target.value,
                                                  },
                                                  () => {
                                                    this.validateMobile2(
                                                      this.state.mobileNo
                                                    );
                                                    if (
                                                      this.state.mobileNo
                                                        .length > 10
                                                    ) {
                                                      this.setState({
                                                        max: true,
                                                      });
                                                    } else {
                                                      this.setState({
                                                        max: false,
                                                      });
                                                    }
                                                  }
                                                );
                                              }}
                                            />

                                            {/* <img
                                             src={userImage}
                                             className="username"
                                           /> */}

                                            {this.state.showmobileError &&
                                            this.state.mobileNo.length > 0 ? (
                                              <p
                                                style={{
                                                  color: "red",
                                                  fontSize: 10,
                                                }}
                                              >
                                                Enter valid Mobile number
                                              </p>
                                            ) : null}
                                            {this.state.max ? (
                                              <div>
                                                <span style={{ color: "red" }}>
                                                  Max Allowed Characters : 10
                                                </span>
                                              </div>
                                            ) : null}
                                          </div>

                                          {this.state.OTPModal && (
                                            <div
                                              className="bottomSpaceBtnLogin"
                                              style={{
                                                position: "relative",
                                              }}
                                            >
                                              <input
                                                name="otp"
                                                className="nameinputTagResp"
                                                type="number"
                                                required
                                                placeholder="OTP"
                                                onChange={(e) => {
                                                  this.setState(
                                                    { otp: e.target.value },
                                                    () => {
                                                      // this.validateOTP(this.state.otp);
                                                    }
                                                  );
                                                }}
                                                style={{
                                                  position: "relative",
                                                  marginBottom: 10,
                                                }}
                                              />
                                              {/* <img
                                               src={secure}
                                               className="username"
                                             /> */}
                                              {this.state.otp.length > 6 ? (
                                                <p
                                                  style={{
                                                    color: "red",
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  Enter valid OTP
                                                </p>
                                              ) : null}

                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    " space-between",
                                                }}
                                              >
                                                <p>
                                                  {this.state.minutes < 10
                                                    ? `0${this.state.minutes}`
                                                    : this.state.minutes}
                                                  :
                                                  {this.state.seconds < 10
                                                    ? `0${this.state.seconds}`
                                                    : this.state.seconds}
                                                </p>
                                                <button
                                                  onClick={(e) =>
                                                    this.setState(
                                                      {
                                                        sentOTP: true,
                                                        loader: false,
                                                        loaderLogin: false,
                                                      },
                                                      () => {
                                                        this.loginUserOTP(e);
                                                      }
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    backgroundColor: "#fff",
                                                    marginBottom: "10px",
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  {this.state.sentOTP
                                                    ? "OTP Sent"
                                                    : "Resend OTP"}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {this.state.showError ? (
                                        <div>
                                          <p style={{ color: "red" }}>
                                            Your credentials are invalid.
                                          </p>
                                        </div>
                                      ) : null}
                                      <div className="loginButton">
                                        <button
                                          // href="/watch"
                                          className="btn btn-solid lgnbtnResp"
                                          // onClick={() => {
                                          //   this.loginUser();
                                          // }}
                                          type="submit"
                                        >
                                          {this.state.btnLoading ? (
                                            <Lottie
                                              options={{
                                                animationData: loader,
                                              }}
                                              style={{
                                                width: "50px",
                                                height: "30px",
                                              }}
                                            />
                                          ) : (
                                            "Login"
                                          )}
                                        </button>
                                        <p style={{ marginTop: "10px" }}>
                                          Forgot password?{" "}
                                          <Link to={"/forget-password"}>
                                            {" "}
                                            Click here
                                          </Link>
                                        </p>
                                        <p style={{ marginTop: "10px" }}>
                                          Want To Register?{" "}
                                          <span
                                            onClick={() => {
                                              this.setState({
                                                loginScreen: false,
                                                billingshipping: true,
                                              });
                                            }}
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {" "}
                                            Click here
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </>
                          ) : // <>
                          // <div className="checkout-titleresp2">
                          //     <div className="blueback"></div>
                          //     <div className="">
                          //       <h3>Account Details</h3>
                          //     </div>
                          //   </div>
                          //   </>
                          this.state.defaultPage ? (
                            <div className="checkout-title1">
                              <div>
                                <h5>Your Selected Course</h5>
                              </div>
                              <div className="booksSection">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <div className="col-md-4 col-sm-4 col-xs-4">
                                    <img
                                      src={selectedCourse}
                                      alt=""
                                      className="checkoutImg"
                                    />
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-4">
                                    <h4 className="courseesname">
                                      Business Mathematics Statistics & LR-Chart
                                    </h4>
                                    <p className="facultiesname">
                                      By Aman Khedia
                                    </p>
                                    <p className="orgArea">
                                      <span className="price"> ₹650</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12 detailRemove">
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                  <span>
                                    <i
                                      class="fa fa-eye"
                                      aria-hidden="true"
                                      style={{
                                        width: "5.32px",
                                        height: "4.89px",
                                        color: "#E92323",
                                        position: "relative",
                                        top: "3px",
                                      }}
                                    ></i>
                                  </span>
                                  <span className="viewdetails">
                                    {" "}
                                    View Detail
                                  </span>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                  <span>
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                      style={{
                                        width: "5.32px",
                                        height: "4.89px",
                                        color: "#E92323",
                                        position: "relative",
                                        top: "3px",
                                      }}
                                    ></i>
                                  </span>
                                  <span className="remove"> Remove</span>
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 col-xs-12 booksarea">
                                <div className="col-md-6 col-sm-6 col-xs-6 selectBooks">
                                  Select Books
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6 seeall">
                                  See all
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 col-xs-12 bookdiv">
                                <div className="col-md-6 col-sm-6 col-xs-6 bookdiv1">
                                  <img
                                    src={toofanimg}
                                    alt=""
                                    className="toofanimg"
                                  />
                                  <div className="booknames">
                                    Business Maths Statistics & LR-Chart
                                  </div>
                                  <p className="byamansir">By Aman Khedia</p>
                                  <div className="twoprices">
                                    <span className="priceFirst">₹299</span>
                                    <span className="priceCrossed">₹299</span>
                                  </div>
                                  <div className="addbook">Add Book</div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6 bookdiv1">
                                  <img
                                    src={toofanimg}
                                    alt=""
                                    className="toofanimg"
                                  />
                                  <div className="booknames">
                                    Business Maths Statistics & LR-Chart
                                  </div>
                                  <p className="byamansir">By Aman Khedia</p>
                                  <div className="twoprices">
                                    <span className="priceFirst">₹299</span>
                                    <span className="priceCrossed">₹299</span>
                                  </div>
                                  <div className="addbook">Add Book</div>
                                </div>
                              </div>
                            </div>
                          ) : this.state.accountDetails ? (
                            <div className="checkout-titleresp2">
                              <div className="blueback"></div>
                              <div className="">
                                <h3>Account Details</h3>
                              </div>
                              <div className="userdeta">
                                <h5>Name</h5>
                                <h4>
                                  {this.state.userDetails
                                    ? this.state.userDetails.FIRST_NAME
                                    : this.state.newAdd?.FIRST_NAME}
                                </h4>

                                <h5>Phone Number</h5>
                                <h4>
                                  +91{" "}
                                  {this.state.userDetails
                                    ? this.state.userDetails.MOBILE
                                    : this.state.newAdd?.MOBILE}
                                </h4>

                                <h5>Email</h5>
                                <h4>
                                  {this.state.userDetails
                                    ? this.state.userDetails.email
                                    : this.state.newAdd?.email}
                                </h4>

                                <h5>Address</h5>
                                <h4>
                                  {this.state.userDetails
                                    ? this.state.userDetails.ADDRESS_1
                                    : this.state.newAdd?.ADDRESS_1}
                                </h4>

                                <div className="col-md-6 col-xs-6 col-sm-6 townarea">
                                  <h5>Town/City</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.CITY
                                      : this.state.newAdd?.CITY}
                                  </h4>
                                </div>

                                <div className="col-md-6 col-xs-6 col-sm-6 townarea">
                                  <h5>State</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.STATE
                                      : this.state.newAdd?.STATE}
                                  </h4>
                                </div>
                              </div>

                              <div
                                className="col-md-6 col-sm-6 col-xs-6 editbtnaddress"
                                onClick={() => {
                                  this.setState({
                                    editAdd: true,
                                    accountDetails: false,
                                    defaultPage: false,
                                    regForm: false,
                                  });
                                }}
                              >
                                <span className="adredt">EDIT ADDRESS</span>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-6 addanother">
                                <span className="addanadd">ADD ANOTHER</span>
                              </div>
                            </div>
                          ) : this.state.editAdd ? (
                            <>
                              <div className="checkout-titleresp4">
                                <div className="blueback"></div>
                                <div className="">
                                  <h3>Account Details</h3>
                                </div>
                                <div className="userdeta">
                                  <h5>Name</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.FIRST_NAME
                                      : this.state.newAdd?.FIRST_NAME}
                                  </h4>

                                  <h5>Phone Number</h5>
                                  <h4>
                                    +91{" "}
                                    {this.state.userDetails
                                      ? this.state.userDetails.MOBILE
                                      : this.state.newAdd?.MOBILE}
                                  </h4>

                                  <h5>Email</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.email
                                      : this.state.newAdd?.email}
                                  </h4>

                                  <h5>Address</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.ADDRESS_1
                                      : this.state.newAdd?.ADDRESS_1}
                                  </h4>

                                  <div className="col-md-6 col-xs-6 col-sm-6 townarea">
                                    <h5>Town/City</h5>
                                    <h4>
                                      {this.state.userDetails
                                        ? this.state.userDetails.CITY
                                        : this.state.newAdd?.CITY}
                                    </h4>
                                  </div>

                                  <div className="col-md-6 col-xs-6 col-sm-6 townarea">
                                    <h5>State</h5>
                                    <h4>
                                      {this.state.userDetails
                                        ? this.state.userDetails.STATE
                                        : this.state.newAdd?.STATE}
                                    </h4>
                                  </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-xs-6 editbtnaddress">
                                  <span className="adredt">EDIT ADDRESS</span>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6 addanother">
                                  <span className="addanadd">ADD ANOTHER</span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "14px",
                                      position: "relative",
                                      top: "40px",
                                    }}
                                  >
                                    {this.state.editCompleteMsg
                                      ? "Details Updated successfully"
                                      : ""}
                                  </span>
                                </div>

                                <div className="edtaddAreaResp">
                                  <div className="form-group">
                                    <label htmlFor="review" className="stree">
                                      Street Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="address_1"
                                      placeholder="House number and street name"
                                      defaultValue={
                                        this.state.address_1
                                          ? this.state.address_1
                                          : this.state.userInfo.ADDRESS_1
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    className="form-controledtadd"
                                    name="address_2"
                                    placeholder="House number and street name"
                                    defaultValue={
                                      this.state.address_2
                                        ? this.state.address_2
                                        : this.state.userInfo.ADDRESS_2
                                    }
                                    required=""
                                    onChange={(e) => {
                                      this.handleInput(e);
                                    }}
                                  />
                                  <div
                                    className="form-group"
                                    style={{ height: "90px" }}
                                  >
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6"
                                      style={{
                                        position: "relative",
                                        left: "-15px",
                                        top: "12px",
                                      }}
                                    >
                                      <div className="form-group">
                                        <label
                                          htmlFor="review"
                                          className="stree"
                                        >
                                          Town / City{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-controledtadd"
                                          name="city"
                                          placeholder="Ex. Nagpur"
                                          defaultValue={
                                            this.state.city
                                              ? this.state.city
                                              : this.state.userInfo.CITY
                                          }
                                          required="true"
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          style={{ width: "135px" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-6 col-xs-6 col-sm-6"
                                      style={{
                                        position: "relative",
                                        left: "-30px",
                                        top: "12px",
                                      }}
                                    >
                                      <div className="form-group">
                                        <label
                                          htmlFor="review"
                                          className="stree"
                                        >
                                          State{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-controledtadd"
                                          name="state"
                                          placeholder="Ex. Maharashtra"
                                          defaultValue={
                                            this.state.state
                                              ? this.state.state
                                              : this.state.userInfo.STATE
                                          }
                                          required="true"
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          style={{ width: "137px" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{
                                      position: "relative",
                                      top: "-10px",
                                      height: "55px",
                                    }}
                                  >
                                    <label htmlFor="review" className="stree">
                                      Pincode{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="pincode"
                                      placeholder="5-digit pincode"
                                      defaultValue={
                                        this.state.pincode
                                          ? this.state.pincode
                                          : this.state.userInfo.PINCODE
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{ position: "relative", top: "3px" }}
                                  >
                                    <label htmlFor="review" className="stree">
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="name"
                                      placeholder="Full Name"
                                      defaultValue={
                                        this.state.name
                                          ? this.state.name
                                          : this.state.userInfo.FIRST_NAME
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="review" className="stree">
                                      Email Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="email"
                                      placeholder="Ex. name@gmail.com"
                                      defaultValue={
                                        this.state.email
                                          ? this.state.email
                                          : this.state.userInfo.email
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="placeorders"
                                    onClick={() => {
                                      this.onUpdate();
                                    }}
                                  >
                                    <div className="ordbtn">UPDATE</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="checkout-titleresp3">
                            <div className="blueback"></div>
                            <div className="col-md-12 col-sm-12 col-xs-12 cr">
                              <div className="col-md-9 col-sm-9 col-xs-9">
                                <p className="boooksname">
                                  {this.state.courseDetails.TITLE}
                                </p>
                                <p className="boooksfacname">
                                  By {this.props.courseDetails?.faculties?.name}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-3 rsbook">
                                ₹
                                {this.props.courseDetails?.TYPE === "BOOKS"
                                  ? this.state.courseDetails.PRICE
                                  : this.props.grand_total}
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12 cr2">
                              <div className="col-md-9 col-sm-9 col-xs-9 discode">
                                <input
                                  type="text"
                                  placeholder="Discount Code"
                                  className="inputdisc"
                                />
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-3 applycoupon">
                                <span className="appbtn">Apply</span>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12 amountDetails">
                              <div className="col-md-8 col-sm-8 col-xs-8">
                                <h5 className="subtotl">Subtotal</h5>
                                <h5>Shipping Charges</h5>
                                <h5>Coupon Amount</h5>
                                {/* <h5>Wallet Amounts</h5> */}
                                <h5></h5>
                                <h5>
                               
                               {/* <input
                                 type="checkbox"
                                 disabled={!this.state.wallet_balance}
                                 checked={this.state.redeemFromWallet}
                                 onChange={this.handleCheckboxChange}
                                 style={{marginRight:"5px"}}
                               />
                              Redeem from Wallet */}
                             </h5>
                              </div>
                              <div className="col-md-4 col-sm-4 col-xs-4">
                                <h4>
                                  ₹
                                  {this.state.courseDetails.TYPE === "BOOKS"
                                    ? this.props.courseDetails?.PRICE
                                    : this.state.grand_total
                                    ? this.state.grand_total === null
                                      ? 0
                                      : this.state.grand_total
                                    : this.state.extendproductPrice === null
                                    ? 0
                                    : this.state.extendproductPrice}
                                </h4>
                                <h4>₹{this.state.shipping}</h4>
                                <h4>₹{this.state.coupon_amount}</h4>
                                {/* {this.state.wallet_balance ? (
                                  <h4>₹{this.state.wallet_balance}</h4>
                                ) : (
                                  <h4></h4>
                                )} */}
                                <h4></h4>
                              </div>
                            </div>

                            <div
                              className={
                                this.props.courseDetails.TYPE === "BOOKS"
                                  ? "borderBtmByBook"
                                  : "borderBtm"
                              }
                            ></div>

                            <div className="col-md-12 col-sm-12 col-xs-12 amountDetails2">
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <h5>Total</h5>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <h4>
                                ₹{this.calculateTotal()}
                                  {/* ₹
                                  {this.state.courseDetails.TYPE === "BOOKS"
                                    ? this.props.courseDetails?.PRICE -
                                      (this.state.coupon_amount || 0)
                                    : this.state.grand_total
                                    ? this.state.grand_total === null
                                      ? 0
                                      : this.state.grand_total
                                    : this.state.extendproductPrice === null
                                    ? 0
                                    : this.state.extendproductPrice} */}
                                  {/* {this.state.grand_total
                                    ? this.state.grand_total === null
                                      ? 0
                                      : this.state.grand_total
                                    : this.state.extendproductPrice === null
                                    ? 0
                                    : this.state.extendproductPrice} */}
                                </h4>
                              </div>
                            </div>
                            {this.state.placeOrderButton ? (
                              <div
                                className="placeorders"
                                onClick={() => {
                                  this.placeOrder();
                                }}
                              >
                                <div
                                  className={
                                    this.props.courseDetails.TYPE === "BOOKS"
                                      ? "ordbtnByBook"
                                      : "ordbtn"
                                  }
                                >
                                  Place Order
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <p className="placeErrorMessageResp">
                              {this.state.placeOrderMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        {/* <div className="col-lg-6 col-sm-12 col-xs-12">
                          <div className="checkout-titleweb">
                            <div>
                                <h5>You can also add books for extra practice and revision</h5>
                            </div>
                            <div className="booksSection">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="col-md-4 col-sm-4 col-xs-4">
                                  <img src={checkoutImg} alt="" className="checkoutImg" />
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-4">
                                  <h4 className="courseesname">Business Mathematics Statistics & LR-Chart Book</h4>
                                  <p className="facultiesname">By Aman Khedia</p>
                                  <p className="ulli">
                                    <ul className="ulnames">
                                      <li>By Aman Khedia</li>
                                      <li>By Aman Khedia</li>
                                      <li>By Aman Khedia</li>
                                    </ul>
                                  </p>
                                  <p className="orgArea">
                                    <span className="original">Original Price:</span><span className="price"> ₹650</span>
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-4">
                                  <div>
                                    <button className="addbutton">ADD</button>
                                  </div>
                                  <p className="disArea">
                                    <span className="original">Discounted Price:</span><span className="disPrice"> ₹400</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="booksSection">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="col-md-4 col-sm-4 col-xs-4">
                                  <img src={checkoutImg} alt="" className="checkoutImg" />
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-4">
                                  <h4 className="courseesname">Business Mathematics Statistics & LR-Chart Book</h4>
                                  <p className="facultiesname">By Aman Khedia</p>
                                  <p className="ulli">
                                    <ul className="ulnames">
                                      <li>By Aman Khedia</li>
                                      <li>By Aman Khedia</li>
                                      <li>By Aman Khedia</li>
                                    </ul>
                                  </p>
                                  <p className="orgArea">
                                    <span className="original">Original Price:</span><span className="price"> ₹650</span>
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-4">
                                  <div>
                                    <button className="removebutton">Remove</button>
                                  </div>
                                  <p className="disArea">
                                    <span className="original">Discounted Price:</span><span className="disPrice"> ₹400</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                          {this.state.billingshipping ? (
                            <div className="checkout-titleweb">
                              <div className="checkout-title">
                                <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12 billingblueback">
                                    <span
                                      className="clickhere"
                                      onClick={() => {
                                        this.setState({
                                          loginScreen: true,
                                          billingshipping: false,
                                        });
                                      }}
                                    >
                                      Click here to login if you have already
                                      registered
                                    </span>
                                  </div>
                                  <div className="col-md-7 col-sm-7 col-xs-7">
                                    <h3 className="bilship">
                                      Billing and Shipping
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <form
                                onSubmit={(e) => {
                                  this.registerUser(e);
                                }}
                              >
                                <div className="">
                                  <div className="row check-out">
                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <div className="field-label">
                                        Name{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="FIRST_NAME"
                                        placeholder="Full Name"
                                        value={this.state.FIRST_NAME}
                                        required
                                        onChange={(e) => {
                                          this.setState(
                                            { FIRST_NAME: e.target.value },
                                            () => {
                                              this.validateName(
                                                this.state.FIRST_NAME
                                              );
                                            }
                                          );
                                        }}
                                        style={{
                                          borderRadius: "5px",
                                          fontSize: "14px",
                                        }}
                                      />
                                      {this.state.showNameError &&
                                      this.state.FIRST_NAME.length > 0 ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: 10,
                                          }}
                                        >
                                          Enter valid name
                                        </p>
                                      ) : null}
                                    </div>
                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                      <label className="field-label">
                                        {" "}
                                        Street address{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="address_1"
                                        placeholder="House number and street name"
                                        value={this.state.address_1}
                                        required
                                        onChange={(e) => {
                                          this.handleInput(e);
                                        }}
                                        style={{
                                          borderRadius: "5px",
                                          fontSize: "14px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{
                                        position: "relative",
                                        left: "-13px",
                                      }}
                                    >
                                      <div className="form-group col-md-4 col-sm-4 col-xs-4">
                                        <label className="field-label">
                                          Town / City{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control2"
                                          name="city"
                                          placeholder="Ex.Nagpur"
                                          value={this.state.city}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { city: e.target.value },
                                              () => {
                                                this.validateCity(
                                                  this.state.city
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showCityError &&
                                        this.state.city.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid City Name
                                          </p>
                                        ) : null}
                                      </div>
                                      <div
                                        className="form-group col-md-4 col-sm-4 col-xs-4"
                                        style={{
                                          position: "relative",
                                          left: "-20px",
                                        }}
                                      >
                                        <label className="field-label">
                                          State{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control2"
                                          name="state"
                                          placeholder="Ex.Maharashtra"
                                          value={this.state.state}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { state: e.target.value },
                                              () => {
                                                this.validateState(
                                                  this.state.state
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showStateError &&
                                        this.state.state.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid State
                                          </p>
                                        ) : null}
                                      </div>
                                      <div
                                        className="form-group col-md-4 col-sm-4 col-xs-4"
                                        style={{
                                          position: "relative",
                                          left: "-40px",
                                        }}
                                      >
                                        <label className="field-label">
                                          Pincode{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control2"
                                          name="pincode"
                                          placeholder="5-digit pincode"
                                          maxLength={6}
                                          value={this.state.pincode}
                                          required
                                          onChange={(e) => {
                                            this.setState({
                                              pincode: e.target.value,
                                            });
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{
                                        position: "relative",
                                        top: "-15px",
                                      }}
                                    >
                                      <label className="field-label">
                                        Phone{" "}
                                        <span style={{ color: "red" }}> *</span>
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="mobile"
                                        placeholder="10-digit number"
                                        value={this.state.mobile}
                                        required
                                        maxLength={10}
                                        onChange={(e) => {
                                          this.setState(
                                            { mobile: e.target.value },
                                            () => {
                                              this.validateMobile(
                                                this.state.mobile
                                              );
                                            }
                                          );
                                        }}
                                        style={{
                                          borderRadius: "5px",
                                          fontSize: "14px",
                                        }}
                                      />
                                      {this.state.showmobileError &&
                                      this.state.mobile.length > 0 ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: 10,
                                            position: "relative",
                                            left: "35px",
                                          }}
                                        >
                                          Enter valid mobile
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>

                                <div className="row check-out">
                                  <div
                                    className="form-group col-md-12 col-sm-12 col-xs-12"
                                    style={{
                                      position: "relative",
                                      top: "-15px",
                                    }}
                                  >
                                    <div className="field-label">
                                      Email address{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </div>
                                    <input
                                      type="email"
                                      name="EMAIL"
                                      placeholder="Ex. name@gmail.com"
                                      className="form-control"
                                      value={this.state.EMAIL}
                                      required
                                      onChange={(e) => {
                                        this.setState(
                                          { EMAIL: e.target.value },
                                          () => {
                                            this.validateEmail(
                                              this.state.EMAIL
                                            );
                                          }
                                        );
                                      }}
                                      style={{
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                      }}
                                    />
                                    {this.state.showEmailError &&
                                    this.state.EMAIL.length > 0 ? (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: 10,
                                          position: "relative",
                                          left: "35px",
                                        }}
                                      >
                                        Enter valid e-mail address
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                {this.state.showError ? (
                                  <div>
                                    <p
                                      style={{
                                        color: "red",
                                        position: "relative",
                                        left: "35px",
                                      }}
                                    >
                                      {this.state.showErrMsg}
                                    </p>
                                  </div>
                                ) : null}
                                <button
                                  to=""
                                  type="submit"
                                  className="btn btn-solid"
                                  style={{
                                    textTransform: "capitalize",
                                    top: "-5px",
                                  }}
                                >
                                  {this.state.btnLoading ? (
                                    <Lottie
                                      options={{
                                        animationData: loader,
                                      }}
                                      style={{
                                        width: "50px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    "create Account & Place Order"
                                  )}
                                </button>
                              </form>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.loginScreen ? (
                            <div
                              style={{
                                background: "#fff",

                                padding: "25px",
                                boxShadow:
                                  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                marginBottom: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <div className="checkout-title">
                                <div className="row">
                                  <h4 className="logintoacc">
                                    Login to your account{" "}
                                  </h4>
                                  {/* <div className="col-md-6"> */}
                                  <p className="h3Login">
                                    If you have shopped with us before, please
                                    enter your details below. If you are a new
                                    customer, please proceed to the Billing
                                    section.
                                  </p>
                                </div>
                              </div>
                              <form
                                onSubmit={(e) => {
                                  if (this.state.OTPModal) {
                                    this.verifyOTP(e);
                                  } else {
                                    this.loginUserOTP(e);
                                  }
                                }}
                              >
                                <div className="check-out checkoutpageLogin">
                                  <div className="">
                                    <div className="row">
                                      <div className="form-group inputWidth col-sm-6 col-md-12">
                                        <div
                                          className="bottomSpace2"
                                          style={{
                                            position: "relative",
                                            top: "10px",
                                          }}
                                        >
                                          <label htmlFor="" className="phnmbr">
                                            Phone Number
                                          </label>
                                          <input
                                            name="mobileNo"
                                            className="nameinputTag"
                                            type="number"
                                            required
                                            placeholder="Phone number"
                                            onChange={(e) => {
                                              this.setState(
                                                {
                                                  mobileNo: e.target.value,
                                                },
                                                () => {
                                                  this.validateMobile2(
                                                    this.state.mobileNo
                                                  );
                                                  if (
                                                    this.state.mobileNo.length >
                                                    10
                                                  ) {
                                                    this.setState({
                                                      max: true,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      max: false,
                                                    });
                                                  }
                                                }
                                              );
                                            }}
                                          />

                                          {/* <img
                                              src={userImage}
                                              className="username"
                                            /> */}

                                          {this.state.showmobileError &&
                                          this.state.mobileNo.length > 0 ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid Mobile number
                                            </p>
                                          ) : null}
                                          {this.state.max ? (
                                            <div>
                                              <span style={{ color: "red" }}>
                                                Max Allowed Characters : 10
                                              </span>
                                            </div>
                                          ) : null}
                                        </div>

                                        {this.state.OTPModal && (
                                          <div
                                            className="bottomSpaceBtnLogin"
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <input
                                              name="otp"
                                              className="nameinputTag"
                                              type="number"
                                              required
                                              placeholder="OTP"
                                              onChange={(e) => {
                                                this.setState(
                                                  { otp: e.target.value },
                                                  () => {
                                                    // this.validateOTP(this.state.otp);
                                                  }
                                                );
                                              }}
                                              style={{
                                                position: "relative",
                                                marginBottom: 10,
                                              }}
                                            />
                                            {/* <img
                                                src={secure}
                                                className="username"
                                              /> */}
                                            {this.state.otp.length > 6 ? (
                                              <p
                                                style={{
                                                  color: "red",
                                                  fontSize: 10,
                                                }}
                                              >
                                                Enter valid OTP
                                              </p>
                                            ) : null}

                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent:
                                                  " space-between",
                                              }}
                                            >
                                              <p>
                                                {this.state.minutes < 10
                                                  ? `0${this.state.minutes}`
                                                  : this.state.minutes}
                                                :
                                                {this.state.seconds < 10
                                                  ? `0${this.state.seconds}`
                                                  : this.state.seconds}
                                              </p>
                                              <button
                                                onClick={(e) =>
                                                  this.setState(
                                                    {
                                                      sentOTP: true,
                                                      loader: false,
                                                      loaderLogin: false,
                                                    },
                                                    () => {
                                                      this.loginUserOTP(e);
                                                    }
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  backgroundColor: "#fff",
                                                  marginBottom: "10px",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                {this.state.sentOTP
                                                  ? "OTP Sent"
                                                  : "Resend OTP"}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {this.state.showError ? (
                                      <div>
                                        <p style={{ color: "red" }}>
                                          Your credentials are invalid.
                                        </p>
                                      </div>
                                    ) : null}
                                    <div className="loginButton">
                                      <button
                                        // href="/watch"
                                        className="btn btn-solid lgnbtn"
                                        // onClick={() => {
                                        //   this.loginUser();
                                        // }}
                                        type="submit"
                                      >
                                        {this.state.btnLoading ? (
                                          <Lottie
                                            options={{
                                              animationData: loader,
                                            }}
                                            style={{
                                              width: "50px",
                                              height: "30px",
                                            }}
                                          />
                                        ) : (
                                          "Login"
                                        )}
                                      </button>
                                      <p style={{ marginTop: "10px" }}>
                                        Forgot password?{" "}
                                        <Link to={"/forget-password"}>
                                          {" "}
                                          Click here
                                        </Link>
                                      </p>
                                      <p style={{ marginTop: "10px" }}>
                                        Want To Register?{" "}
                                        <span
                                          onClick={() => {
                                            this.setState({
                                              loginScreen: false,
                                              billingshipping: true,
                                            });
                                          }}
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {" "}
                                          Click here
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          ) : null}
                          {this.state.accountDetails ? (
                            <div className="checkout-titleweb_account_details">
                              <div className="">
                                <h3>Account Details</h3>
                              </div>
                              <div className="userdeta">
                                <div className="col-md-6 col-xs-6 col-sm-6 nameareadetail">
                                  <h5>Name</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.FIRST_NAME
                                      : this.state.newAdd?.FIRST_NAME}
                                  </h4>
                                </div>

                                <div className="col-md-6 col-xs-6 col-sm-6">
                                  <h5>Phone Number</h5>
                                  <h4>
                                    +91{" "}
                                    {this.state.userDetails
                                      ? this.state.userDetails.MOBILE
                                      : this.state.newAdd?.MOBILE}
                                  </h4>
                                </div>

                                <div
                                  className="col-md-12 col-xs-12 col-sm-12"
                                  style={{
                                    position: "relative",
                                    left: "-15px",
                                  }}
                                >
                                  <h5>Email</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.email
                                      : this.state.newAdd?.email}
                                  </h4>
                                </div>

                                <div
                                  className="col-md-12 col-xs-12 col-sm-12"
                                  style={{
                                    position: "relative",
                                    left: "-15px",
                                  }}
                                >
                                  <h5>Address</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.ADDRESS_1
                                      : this.state.newAdd?.ADDRESS_1}
                                  </h4>
                                </div>

                                <div className="col-md-6 col-xs-6 col-sm-6 townarea">
                                  <h5>Town/City</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.CITY
                                      : this.state.newAdd?.CITY}
                                  </h4>
                                </div>

                                <div className="col-md-6 col-xs-6 col-sm-6 townarea2">
                                  <h5>State</h5>
                                  <h4>
                                    {this.state.userDetails
                                      ? this.state.userDetails.STATE
                                      : this.state.newAdd?.STATE}
                                  </h4>
                                </div>
                              </div>

                              <div
                                className="col-md-6 col-sm-6 col-xs-6 editbtnaddress"
                                onClick={() => {
                                  this.setState({
                                    editAdd: true,
                                    accountDetails: false,
                                    defaultPage: false,
                                    regForm: false,
                                  });
                                }}
                              >
                                <span className="adredt">EDIT ADDRESS</span>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-6 addanother">
                                <span className="addanadd">ADD ANOTHER</span>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: "green",
                                    fontSize: "20px",
                                    position: "relative",
                                    top: "40px",
                                  }}
                                >
                                  {this.state.editCompleteMsg
                                    ? "Details Updated successfully"
                                    : ""}
                                </span>
                              </div>
                            </div>
                          ) : this.state.editAdd ? (
                            <>
                              <div className="checkout-titleweb_account_detailsEdit">
                                <div className="">
                                  <h3>Account Details</h3>
                                </div>
                                <div className="userdeta">
                                  <div className="col-md-6 col-xs-6 col-sm-6 nameareadetail">
                                    <h5>Name</h5>
                                    <h4>
                                      {this.state.userDetails?.FIRST_NAME}
                                    </h4>
                                  </div>

                                  <div className="col-md-6 col-xs-6 col-sm-6">
                                    <h5>Phone Number</h5>
                                    <h4>
                                      +91 {this.state.userDetails?.MOBILE}
                                    </h4>
                                  </div>

                                  <div
                                    className="col-md-12 col-xs-12 col-sm-12"
                                    style={{
                                      position: "relative",
                                      left: "-15px",
                                    }}
                                  >
                                    <h5>Email</h5>
                                    <h4>{this.state.userDetails?.email}</h4>
                                  </div>

                                  <div
                                    className="col-md-12 col-xs-12 col-sm-12"
                                    style={{
                                      position: "relative",
                                      left: "-15px",
                                    }}
                                  >
                                    <h5>Address</h5>
                                    <h4>{this.state.userDetails?.ADDRESS_1}</h4>
                                  </div>

                                  <div className="col-md-6 col-xs-6 col-sm-6 townarea">
                                    <h5>Town/City</h5>
                                    <h4>{this.state.userDetails?.CITY}</h4>
                                  </div>

                                  <div className="col-md-6 col-xs-6 col-sm-6 townarea2">
                                    <h5>State</h5>
                                    <h4>{this.state.userDetails?.STATE}</h4>
                                  </div>
                                </div>

                                <div
                                  className="col-md-6 col-sm-6 col-xs-6 editbtnaddress"
                                  onClick={() => {
                                    this.setState({
                                      editAdd: true,
                                      accountDetails: false,
                                      defaultPage: false,
                                      regForm: false,
                                    });
                                  }}
                                >
                                  <span className="adredt">EDIT ADDRESS</span>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6 addanother">
                                  <span className="addanadd">ADD ANOTHER</span>
                                </div>

                                <div className="edtaddArea">
                                  <div className="form-group">
                                    <label htmlFor="review" className="stree">
                                      Street Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="address_1"
                                      placeholder="House number and street name"
                                      defaultValue={
                                        this.state.address_1
                                          ? this.state.address_1
                                          : this.state.userInfo.ADDRESS_1
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{ height: "90px" }}
                                  >
                                    <div
                                      className="col-md-4 col-xs-4 col-sm-4"
                                      style={{
                                        position: "relative",
                                        left: "-13px",
                                        top: "12px",
                                      }}
                                    >
                                      <div className="form-group">
                                        <label
                                          htmlFor="review"
                                          className="stree"
                                        >
                                          Town / City{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-controltown"
                                          name="city"
                                          placeholder="Ex. Nagpur"
                                          defaultValue={
                                            this.state.city
                                              ? this.state.city
                                              : this.state.userInfo.CITY
                                          }
                                          required="true"
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          style={{ width: "191.11px" }}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      className="col-md-4 col-xs-4 col-sm-4"
                                      style={{
                                        position: "relative",
                                        left: "-13px",
                                        top: "12px",
                                      }}
                                    >
                                      <div className="form-group">
                                        <label
                                          htmlFor="review"
                                          className="stree"
                                          style={{
                                            position: "relative",
                                            left: "8px",
                                          }}
                                        >
                                          State{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-controltown"
                                          name="state"
                                          placeholder="Ex. Maharashtra"
                                          defaultValue={
                                            this.state.state
                                              ? this.state.state
                                              : this.state.userInfo.STATE
                                          }
                                          required="true"
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          style={{
                                            width: "191.11px",
                                            position: "relative",
                                            left: "8px",
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      className="col-md-4 col-xs-4 col-sm-4"
                                      style={{
                                        position: "relative",
                                        left: "-13px",
                                        top: "12px",
                                      }}
                                    >
                                      <div className="form-group">
                                        <label
                                          htmlFor="review"
                                          className="stree"
                                          style={{
                                            position: "relative",
                                            left: "17px",
                                          }}
                                        >
                                          Pincode{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="pincode"
                                          placeholder="5-digit pincode"
                                          defaultValue={
                                            this.state.pincode
                                              ? this.state.pincode
                                              : this.state.userInfo.PINCODE
                                          }
                                          required="true"
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          style={{
                                            width: "191.11px",
                                            position: "relative",
                                            left: "17px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="review" className="stree">
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="name"
                                      placeholder="Full Name"
                                      defaultValue={
                                        this.state.name
                                          ? this.state.name
                                          : this.state.userInfo.FIRST_NAME
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="review" className="stree">
                                      Email Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-controledtadd"
                                      name="email"
                                      placeholder="Ex. name@gmail.com"
                                      defaultValue={
                                        this.state.email
                                          ? this.state.email
                                          : this.state.userInfo.email
                                      }
                                      required="true"
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>

                                  <div
                                    className="placeorders"
                                    onClick={() => {
                                      this.onUpdate();
                                    }}
                                  >
                                    <div className="ordbtn">UPDATE</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xs-6">
                          <div className="checkout-title2">
                            <div className="">
                              <h5>Order Summary</h5>
                            </div>
                            <div className="booksSection">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                  <img
                                    src={
                                      Constants.imgUrl +
                                      this.props?.courseDetails
                                        ?.THUMB_IMAGE_PATH
                                    }
                                    alt=""
                                    className="checkoutImg"
                                  />
                                </div>
                                <div
                                  className="col-md-6 col-sm-6 col-xs-6"
                                  style={{ position: "relative", left: "15px" }}
                                >
                                  <h4 className="courseesname">
                                    {this.state.courseDetails.TITLE}
                                  </h4>
                                  <p className="facultiesname">
                                    <span className="batches">
                                      {this.props.batch_name}:{" "}
                                    </span>
                                    <span className="att">
                                      {this.props.attempt}
                                    </span>
                                  </p>
                                  <p className="ulli">
                                    <span>
                                      By{" "}
                                      {
                                        this.props.courseDetails?.faculties
                                          ?.name
                                      }
                                    </span>
                                  </p>
                                  <p className="orgArea">
                                    <span className="original">
                                      Course Price:
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                  <div>
                                    {/* <button className="addbutton">ADD</button> */}
                                    {/* <button className="removebutton">Remove</button> */}
                                  </div>
                                  <p className="disArea">
                                    <span className="disPrice">
                                      {" "}
                                      ₹
                                      {this.props.grand_total
                                        ? this.props.grand_total
                                        : this.props.courseDetails?.PRICE}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* <div className="booksSection">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                  <img src={checkoutImg} alt="" className="checkoutImg" />
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                  <h4 className="courseesname">Quantitative Aptitude</h4>
                                  <p className="facultiesname">
                                    <span className="batches">Param Batch: </span><span className="att">June - 2024</span>
                                  </p>
                                  <p className="ulli">
                                    <span>By Aman Khedia</span>
                                  </p>
                                  <p className="orgArea">
                                    <span className="original">Course Price:</span>
                                  </p>
                                </div>
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                  <div>
                                    <button className="addbutton">ADD</button>
                                    <button className="removebutton">Remove</button>
                                  </div>
                                  <p className="disArea">
                                    <span className="disPrice"> ₹400</span>
                                  </p>
                                </div>
                              </div>
                            </div> */}

                            <div className="col-md-12 col-sm-12 col-xs-12 amountDetails">
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <h5 className="subtotl">Subtotal</h5>
                                <h5>Shipping Charges</h5>
                                <h5>Coupon Amount</h5> 
                                {/* <h5>
                               
                                   
                                  Wallet Amounts
                                </h5> */}
                                <h5></h5>
                                <h5>
                               
                                  {/* <input
                                    type="checkbox"
                                    disabled={!this.state.wallet_balance}
                                    checked={this.state.redeemFromWallet}
                                    onChange={this.handleCheckboxChange}
                                    style={{marginRight:"5px"}}
                                  />
                                 Redeem from Wallet */}
                                </h5>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <h4>
                                  ₹
                                  {this.state.courseDetails.TYPE === "BOOKS"
                                    ? this.props.courseDetails?.PRICE
                                    : this.props.grand_total
                                    ? this.props.grand_total === null
                                      ? 0
                                      : this.props.grand_total
                                    : this.state.extendproductPrice === null
                                    ? 0
                                    : this.state.extendproductPrice}
                                </h4>
                                <h4>₹{this.state.shipping}</h4>
                                <h4>₹{this.state.coupon_amount}</h4>
                                <h4></h4>
                                {/* {this.state.wallet_balance ? (
                                  <h4>₹{this.state.wallet_balance}</h4>
                                ) : (
                                  <h4></h4>
                                )} */}
                              </div>
                            </div>

                            <div className="borderBtm"></div>

                            <div className="col-md-12 col-sm-12 col-xs-12 amountDetails2">
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <h5  
                                // onClick={() => this.setState({ isOrderLoading: true })}
                                >
                                  Total</h5>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-6">
                                <h4>
                                ₹{this.calculateTotal()}
                                  {/* ₹
                                  {this.state.courseDetails.TYPE === "BOOKS"
                                    ? this.props.courseDetails?.PRICE -
                                      (this.state.coupon_amount || 0)
                                    : this.state.grand_total
                                    ? this.state.grand_total === null
                                      ? 0
                                      : this.state.grand_total
                                    : this.state.extendproductPrice === null
                                    ? 0
                                    : this.state.extendproductPrice} */}
                                  {/* {this.state.grand_total
                                    ? this.state.grand_total === null
                                      ? 0
                                      : this.state.grand_total
                                    : this.state.extendproductPrice === null
                                    ? 0
                                    : this.state.extendproductPrice} */}
                                </h4>
                              </div>
                            </div>
                            {this.state.placeOrderButton ? (
                              <div
                                className="placeorders"
                                onClick={() => {
                                  this.placeOrder();
                                }}
                              >
                                <div className="ordbtn">Place Order</div>
                              </div>
                            ) : (
                              ""
                            )}

                            <p className="placeErrorMessage">
                              {this.state.placeOrderMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Modal
                  visible={this.state.paymentError}
                  width="350"
                  height="300"
                  effect="fadeInUp"
                >
                  <div>
                    <h4> Something went wrong .</h4>
                    <span onClick={this.placeOrder}>Try Again</span>
                    <span onClick={this.closeModal}>Cancel</span>
                  </div>
                </Modal>
              </div>
            </div>
          </section>
          {/* {this.state.isLoading && (
            <div
              style={{
                background: "#00000082",
                position: "fixed",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
                top: 0,
                left: 0,
              }}
            >
              <h1 style={{ color: "#fff" }}>Placing order...</h1>
            </div>
          )} */}
          {this.state.isLoading && (
  <div
    style={{
      background: "#00000082",
      position: "fixed",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
      top: 0,
      left: 0,
    }}
  >
    <div
      style={{
        background: "#ffffff",
        padding: "20px 30px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        maxWidth: "600px",
        width: "90%",
      }}
    >
        <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={150}
        width={260}
      />
      
      <p style={{ color: "#333", marginBottom: "10px",marginTop: "10px",fontSize: "25px"  , fontWeight:"600" }}>Please Wait ...</p>
      {/* <p style={{ color: "#555", fontSize: "17px" , fontWeight:"500", marginBottom: "10px" }}>
        While we are processing your order
      </p> */}
      <p style={{ color: "#888", fontSize: "15px" }}>
        Do not click on the back button or refresh the page
      </p>
    </div>
  </div>
)}


{this.state.isOrderLoading && (
  <div
    style={{
      background: "#00000082",
      position: "fixed",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
      top: 0,
      left: 0,
    }}
  >
    <div
      style={{
        background: "#ffffff",
        padding: "20px 30px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        maxWidth: "600px",
        width: "90%",
      }}
    >
      <div style={{width:"250px" , height:"180"}}>

      </div>
       <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationSuccess,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={180}
        width={180}
      />
       
      
      {/* <p style={{ color: "#333", marginBottom: "10px",marginTop: "10px",fontSize: "25px"  , fontWeight:"600" }}>Tha</p> */}
      <p style={{ color: "#555", fontSize: "17px" , fontWeight:"500", marginBottom: "10px" , marginTop:"10px" }}>
      Thank you for your order! We have received your request and are currently processing it. Our team will get back to you shortly with further updates.
      </p>

      <button  onClick={this.handleGoToDashboard} className="btn" style={{color:"white",marginTop:"10px"}}>Go to dashboard</button>
      {/* <p style={{ color: "#888", fontSize: "15px" }}>
        Do not click on the back button or refresh the page
      </p> */}
    </div>
  </div>
)}

        </>
      );
    }
  }
}
